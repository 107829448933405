import { useState } from 'react'
import { SearchPageProps } from 'shared/interfaces'
import Form from './Form'
import Icon from 'shared/icons/066-alert.svg'
import CloseIcon from 'shared/icons/015-X-tag.svg'
import CheckIcon from 'shared/icons/045-hacken.svg'
import { accentColor } from 'config/style'
import translate from 'config/translate'

const infoTextCreate = `Lass dich regelmäßig über passende Jobangebote zu dieser Suchanfrage per E-Mail informieren.`

export const CreateJobAlert: React.FC<{
	email: string
	hasNotification: boolean
	query: SearchPageProps['searchQuery']
	lang: string
}> = ({ email, query, hasNotification, lang }) => {
	const [activation, setActivation] = useState<
		'init' | 'pending' | 'created' | 'failed' | 'closed'
	>(hasNotification ? 'closed' : 'init')
	if (query['mr-name'] === '/mutation/create-jobalert' && activation === 'pending') {
		setActivation(query['mr-suc'] === '1' ? 'created' : 'failed')
	}

	const texts: { [state in typeof activation]: string } = {
		init: translate('Job-Alert aktivieren', lang),
		pending: translate('Job-Alert wird aktiviert', lang),
		created: translate('Job-Alert ist aktiviert', lang),
		failed: translate('Job-Alert fehlgeschlagen', lang),
		closed: '',
	}

	return (
		<div className={activation}>
			<span className="close" onClick={() => setActivation('closed')}>
				<CloseIcon />
			</span>
			<h4>
				<Icon />
				<span>{translate('Jobs per E-Mail', lang)}</span>
			</h4>
			<p>{translate(infoTextCreate, lang)}</p>
			<span className="email">{email}</span>
			<Form
				action="/mutation/create-jobalert"
				method="post"
				onSubmit={() => {
					setActivation('pending')
				}}
				redirectUrl={{ pathname: '/search', query }}
				hiddenInputs={{
					q: query.q,
					loc: query.loc,
					pf: query.pf,
					r: query.r,
				}}
			>
				<button type="submit" disabled={activation !== 'init'}>
					{texts[activation]}
					{activation === 'created' ? <CheckIcon /> : null}
				</button>
			</Form>
			<style jsx>{`
				h4 {
					display: flex;
					align-items: center;
					color: #333;
					text-transform: uppercase;
					font-size: 15px;
				}
				h4 :global(svg) {
					height: 26px;
					margin-right: 8px;
				}
				p {
					margin-top: 10px;
					color: #333;
					font-size: 14px;
					line-height: 1.3;
				}
				div {
					padding: 20px;
					background: white;
					border: 2px solid ${accentColor};
					position: relative;
				}
				@media (max-width: 768px) {
					div {
						margin: 0 8px;
					}
				}
				.closed {
					display: none;
				}
				.email {
					display: block;
					border: 1px solid lightgray;
					color: gray;
					padding: 10px;
				}
				button[type='submit'] {
					background: ${accentColor};
					color: white;
					display: flex;
					align-items: center;
					justify-content: center;
					appearance: none;
					border: none;
					padding: 8px 0;
					border-radius: 5px;
					margin-top: 20px;
					width: 100%;
				}
				button :global(svg) {
					width: 18px;
					height: 18px;
					fill: white;
					stroke: white;
					margin-left: 10px;
				}
				button:focus {
					outline: none;
				}

				.close {
					width: 20px;
					height: 20px;
					position: absolute;
					top: 20px;
					right: 20px;
					display: none;
					cursor: pointer;
				}

				.close :global(svg) {
					fill: gray;
				}

				.failed .close,
				.created .close {
					display: block;
				}
			`}</style>
		</div>
	)
}

export const infoTextRegister = `Melde dich an oder erstelle ein kostenloses Konto, um regelmäßig passende Jobangebote zu deiner Suche per E-Mail zu erhalten.`

export const CreateJobAlertAnonymous: React.FC<{
	redirectPath: string
	lang: string
}> = ({ redirectPath, lang }) => (
	<div>
		<h4>
			<Icon />
			<span>{translate('Jobs per E-Mail', lang)}</span>
		</h4>
		<p>{translate(infoTextRegister, lang)}</p>
		<a href={`/api/login?url=${encodeURIComponent(redirectPath)}`}>
			{translate('Anmelden', lang)}
		</a>
		<style jsx>{`
			h4 {
				display: flex;
				align-items: center;
				color: #333;
				text-transform: uppercase;
				font-size: 15px;
			}
			h4 :global(svg) {
				width: 30px;
				height: 30px;
				margin-right: 8px;
			}
			p {
				margin-top: 10px;
				color: #333;
				font-size: 14px;
				line-height: 1.3;
			}
			div {
				padding: 20px;
			}
			a {
				text-decoration: none;
				display: flex;
				background: ${accentColor};
				color: white;
				padding: 10px;
				justify-content: center;
				border-radius: 5px;
			}
		`}</style>
	</div>
)
