import BottomBar, { Props } from 'shared/components/BottomBar'
import { maxWidth } from 'shared/components/Container'

const BottomBarSplitRight: React.FC<Props> = props => (
	<div>
		<BottomBar {...props} />

		<style jsx>{`
			div {
				max-width: ${maxWidth}px;
				position: sticky;
				bottom: 0px;
				max-width: 60%;
				margin-left: auto;
			}
			@media (max-width: 768px) {
				div {
					display: none;
				}
			}
		`}</style>
	</div>
)

export default BottomBarSplitRight
