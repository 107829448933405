import translate from 'config/translate'
export const Tabs: React.FC<{ hasCompany: boolean; lang: string }> = ({
	hasCompany,
	lang,
}) => (
	<>
		<input type="radio" name="job-tabs" id="tab-job" defaultChecked />
		<input type="radio" name="job-tabs" id="tab-company" />
		{hasCompany ? (
			<nav>
				<label htmlFor="tab-job">{translate('Stellenanzeige', lang)}</label>
				<label htmlFor="tab-company">{translate('Unternehmen', lang)}</label>
			</nav>
		) : null}
		<style jsx>{`
			input {
				display: none;
			}
			label {
				background-color: #f3f2f2;
				border-bottom: 1px solid #e9e8e8;
				border-top: 1px solid #f3f2f2;
				color: #9e9c9c;
				display: inline-block;
				flex-grow: 1;
				padding: 12px;
				text-align: center;
				text-transform: uppercase;
				-webkit-tap-highlight-color: transparent;
			}

			label + label {
				border-left: 1px solid #e9e8e8;
			}

			#tab-job:checked ~ nav label[for='tab-job'],
			#tab-company:checked ~ nav label[for='tab-company'] {
				background-color: white;
				border-bottom: none;
				color: black;
				font-weight: bold;
			}

			nav {
				display: none;
			}

			@media (min-width: 600px) {
				label {
					padding: 20px;
				}
			}

			@media (max-width: 768px) {
				nav {
					align-items: center;
					display: flex;
				}
			}
			@media print {
				nav {
					display: none;
				}
			}
		`}</style>
	</>
)

export const OfferTab: React.FC = ({ children }) => (
	<div className="offer">
		{children}
		<style jsx>{`
			@media (max-width: 768px) {
				:global(#tab-company:checked) ~ .offer {
					display: none;
				}
			}
		`}</style>
	</div>
)

export const CompanyTab: React.FC = ({ children }) => (
	<div className="company-tab">
		{children}
		<style jsx>{`
			@media (max-width: 768px) {
				:global(#tab-job:checked) ~ .company-tab {
					display: none;
				}
			}
		`}</style>
	</div>
)
