import translate from 'config/translate'
import Link from 'next/link'
import { linkAccentColor } from 'config/style'

const BackButton: React.FC<{ lang: string; backUrl: string }> = ({ lang, backUrl }) => (
	<>
		<Link href={backUrl}>
			<a className="backbutton" rel="nofollow">
				{translate('Zurück zur Trefferliste', lang)}
			</a>
		</Link>
		<style jsx>{`
			a {
				color: ${linkAccentColor};
				text-decoration: none;
				display: flex;
				height: 40px;
				justify-content: left;
				align-items: center;
				padding: 6px 10px;
				-webkit-tap-highlight-color: transparent;
			}
			@media print {
				a {
					display: none;
				}
			}
		`}</style>
	</>
)

export default BackButton
