import { KeyboardEvent } from 'react'
import { useIsMobile } from 'shared/customHooks'
import { IJobOffer, INotedOffer, SearchPageQueryParams } from 'shared/interfaces'
import JobListItem from './JobListItem'

export const isNoted = (notedOffers: Readonly<INotedOffer[]>, documentId: string) =>
	notedOffers.find(notedOffer => notedOffer.offerId === documentId)

const onKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
	const { currentTarget, keyCode } = event

	if (!currentTarget) {
		return
	}

	const target =
		keyCode === 38
			? currentTarget.previousElementSibling
			: keyCode === 40
			? currentTarget.nextElementSibling
			: undefined

	if (!target || !target.firstElementChild) {
		return
	}

	;(target.firstElementChild as HTMLElement).focus()
	event.preventDefault()
	event.stopPropagation()
}

export const JobList: React.FC<
	Readonly<{
		jobs: Readonly<IJobOffer[]>
		notedOffers: Readonly<INotedOffer[]>
		query: SearchPageQueryParams
		showBookmarkIcon: boolean
		lang: string
	}>
> = ({ jobs, notedOffers = [], query, showBookmarkIcon, lang }) => {
	const isMobile = useIsMobile()
	return (
		<ul>
			{jobs.map((job, index) => (
				<li
					key={`${index}-${job.documentId}-${isMobile ? 'm' : 'd'}`}
					onKeyDown={onKeyDown}
					className={query.selected === job.documentId ? 'selected' : ''}
					data-id={job.documentId}
				>
					<JobListItem
						{...job}
						notedOffers={notedOffers}
						query={query}
						isMobile={isMobile}
						showBookmarkIcon={showBookmarkIcon}
						lang={lang}
					/>
				</li>
			))}
			<style jsx>{`
				ul {
					list-style: none;
				}
				ul:not(:first-child) {
					margin-top: 10px;
				}
				li {
					background: white;
					line-height: 1.35;
					margin-bottom: 10px;
					margin-left: -20px;
					margin-right: -20px;
					position: relative;
				}

				@media (max-width: 768px) {
					li {
						margin-left: 0px;
						margin-right: 0px;
						position: relative;
					}
				}
				@media (min-width: 769px) {
					li.selected {
						box-shadow: 0 8px 8px -5px rgba(140, 140, 140, 0.6196078431372549);
					}

					li {
						margin-left: 0;
						margin-right: 0;
					}
				}
			`}</style>
		</ul>
	)
}

export default JobList
