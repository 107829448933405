import { IJobOffer } from 'shared/interfaces'
import { getTimeAgo } from 'shared/Util'
import Calender from 'shared/icons/003-kalender.svg'
import Clock from 'shared/icons/031-uhr.svg'
import Pin from 'shared/icons/011-pin.svg'
import Image from './Image'
import { secondaryAccentColor } from 'config/style'
import translate from 'config/translate'

const ListItemHead: React.FC<IJobOffer & { lang: string }> = ({
	logoUrl,
	companyName,
	title,
	location,
	firstFound,
	hours,
	lang,
}) => (
	<div className="list-item">
		{logoUrl ? (
			<div className="logo">
				<Image src={logoUrl} alt="logo" width="100%" />
			</div>
		) : null}
		<div className="title">
			<span className="company">{companyName}</span>
			<h3>{title}</h3>
		</div>
		<div className="infos">
			<div className="location">
				{location ? <Pin className="pin-offerlist-svg" /> : null}
				<span className="locationtext">{location ? location.trim() : ''}</span>
			</div>
			{firstFound ? (
				<div className="age">
					<Calender className={'calender-offerlist-svg'} />
					<span className="calender">{getTimeAgo(firstFound, lang)}</span>
				</div>
			) : null}
			<div className="hours">
				{hours && hours.length > 0 ? (
					<Clock className="clock-offerlist-svg" />
				) : null}
				{hours ? (
					<div className="hours">
						{hours.map(hour => (
							<span key={hour}>{translate(hour, lang)}</span>
						))}
					</div>
				) : null}
			</div>
		</div>
		<style jsx>{`
			:global(.pin-offerlist-svg, .clock-offerlist-svg, .calender-offerlist-svg) {
				width: 16px;
				margin-right: 6px;
				height: 16px;
			}
			:global(.pin-offerlist-svg path, .clock-offerlist-svg
					path, .calender-offerlist-svg path) {
				fill: rgb(85, 85, 85);
			}
			.logo {
				width: 86px;
				min-height: 61px;
				padding-right: 10px;
			}
			.company {
				color: ${secondaryAccentColor};
				font-size: 14px;
			}
			.title {
				flex-grow: 1;
				flex-basis: 50%;
			}
			.hours,
			.location,
			.age {
				color: rgb(85, 85, 85);
				font-size: 12px;
				display: flex;
				align-content: center;
				margin-right: 15px;
			}

			.location {
				width: 100%;
			}
			.hours span + span::before {
				content: ', ';
			}
			.location {
				margin-bottom: 3px;
			}
			.locationtext {
				max-width: 81%;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
			.infos {
				width: 100%;
				flex-flow: row wrap;
				display: flex;
			}
			h3 {
				color: #333;
				grid-area: title;
				font-size: 14px;
				margin-top: 2px;
				margin-bottom: 8px;
				font-weight: 600;
			}
			.list-item {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				text-decoration: none;
			}
		`}</style>
	</div>
)

export default ListItemHead
