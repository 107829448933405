import { NextPage } from 'next'
import ErrorPage from 'next/error'
import SearchPage from 'pages/search'
import BrowsingPage from 'pages/browsing'
import { getSeoResourceForUrl } from 'shared/api'
import browsingView from 'shared/views/browsing'
import { ISeoSearchPage, SearchPageProps, BrowsingPageProps } from 'shared/interfaces'
import { IncomingMessage, OutgoingMessage } from 'http'
import { logError } from 'shared/Util'

type Props = ({} | SearchPageProps | BrowsingPageProps) & {
	type?: 'browsing'
	statusCode?: number
}

const SeoPage: NextPage<Props> = props => {
	if (props.statusCode) {
		return <ErrorPage statusCode={props.statusCode} />
	}

	if (props.type === 'browsing') {
		return <BrowsingPage {...(props as BrowsingPageProps)} />
	}

	return <SearchPage {...(props as SearchPageProps)} />
}

SeoPage.getInitialProps = async ctx => {
	const url =
		'/' +
		ctx.query.l1 +
		(ctx.query.l2 ? '/' + ctx.query.l2 : '') +
		(ctx.query.l3 ? '/' + ctx.query.l3 : '') +
		(ctx.query.l4 ? '/' + ctx.query.l4 : '')
	if (typeof window === 'undefined') {
		if (ctx.res && ctx.req) {
			const compression = require('compression')
			// Compression is required since net size of SEO page responses can go up to 9MB!
			// The lambda (now serverless / AWS) has a limit of 6MB response size.
			// Using the compression, the lamda response size before the ZEIT/now proxy goes down
			// to 600kb max.
			const compressionMiddleware = compression() as (
				req: IncomingMessage,
				res: OutgoingMessage,
				next: () => void,
			) => void
			compressionMiddleware(ctx.req, ctx.res, () => {})
		}
	}

	try {
		const seoResource = await getSeoResourceForUrl(ctx, url)
		if (seoResource.Type.match('SearchProfile')) {
			const searchProfile = (seoResource as ISeoSearchPage).SearchProfile
			const query = {
				...(ctx.query as { l1: string; l2: string; l3: string; l4: string }),
				loc: searchProfile.Ort,
				q:
					searchProfile.Suchwort +
					(searchProfile.Firmensuchwort
						? ' ' + searchProfile.Firmensuchwort
						: ''),
			}
			delete query.l1
			delete query.l2
			delete query.l3
			delete query.l4

			if (SearchPage.getInitialProps) {
				const props = await SearchPage.getInitialProps({
					...ctx,
					query,
				})

				return props
			}
		} else if (seoResource.Type === 'BrowsingPage') {
			if (ctx.res) {
				ctx.res.setHeader('Cache-Control', 's-maxage=200, stale-while-revalidate')
			}

			return {
				type: 'browsing',
				seoBrowsingPage: seoResource,
				...(await browsingView(ctx)),
			}
		}
	} catch (err) {
		if (process.env.NODE_ENV !== 'test') {
			logError('SeoPage.getInitialProps', err)
		}
	}

	return {
		statusCode: 404,
	}
}

export default SeoPage
