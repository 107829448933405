const CategoryHeader = ({ children }) => (
	<h4>
		{children}
		<style jsx>{`
			h4 {
				text-transform: uppercase;
				color: rgb(156, 156, 156);
				font-size: 14px;
				font-weight: 500;
				margin-right: 10px;
				display: flex;
				align-items: center;
				height: 27px;
				white-space: nowrap;
			}
		`}</style>
	</h4>
)

export default CategoryHeader
