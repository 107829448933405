import { JobPageProps, IJobOffer } from 'shared/interfaces'
import CompanyInfo from '../CompanyInfo'
import JobOffer from './JobOffer'
import IFrame from './IFrame'
import { SimilarJobs, TopJobs } from '../topAndSimilarJobs/TopAndSimilarJobs'
import JobContainer from './JobContainer'
import { Tabs, OfferTab, CompanyTab } from './Tabs'

const isPremiumHtml = (jobOffer: IJobOffer) =>
	jobOffer.features && jobOffer.features.displayType === 'IFrameWithMetadata'

const isStepstone = (jobOffer: IJobOffer) =>
	jobOffer.features &&
	jobOffer.features.displayType === 'IFrameWithoutMetadata' &&
	jobOffer.offerUrl

export type Props = Pick<
	JobPageProps,
	'job' | 'selected' | 'similarJobs' | 'standAlone' | 'topJobs' | 'backUrl'
>

export const Job: React.FC<Props> = ({
	job,
	selected,
	similarJobs,
	standAlone,
	topJobs,
	backUrl,
}) => {
	return (
		<JobContainer
			lang="de"
			isSelected={!!selected}
			standAlone={!!standAlone}
			backUrl={backUrl}
		>
			{isStepstone(job.jobOffer) && job.jobOffer.offerUrl ? (
				<IFrame
					src={job.jobOffer.offerUrl
						.replace(
							'https://www.stepstone.de',
							'https://stepstone.kimeta.de',
						)
						.replace('https://stepstone.de', 'https://stepstone.kimeta.de')}
				/>
			) : (
				<>
					<Tabs hasCompany={!!job.companyInfo} lang="de" />
					<OfferTab>
						{isPremiumHtml(job.jobOffer) ? (
							<IFrame src={'/iFrame/' + job.jobOffer.anzeigenUId} />
						) : (
							<JobOffer {...job.jobOffer} lang="de" />
						)}
					</OfferTab>
					{job.companyInfo ? (
						<CompanyTab>
							<div className="company">
								<CompanyInfo {...job.companyInfo} />
							</div>
							{similarJobs.length > 0 ? (
								<SimilarJobs jobs={similarJobs} />
							) : null}
						</CompanyTab>
					) : null}
					{topJobs.length > 0 ? <TopJobs jobs={topJobs} /> : null}
				</>
			)}
		</JobContainer>
	)
}

export default Job
