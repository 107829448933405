import FilterMenu from './FilterMenu'
import CategoryTab from './CategoryTab'
import ContractTab from './ContractTab'
import MyFilters from './MyFilters'
import categoryNames from './categoryNames'
import { maxWidth } from 'shared/components/Container'

export const myFilterCategories = [
	'position',
	'standort',
	'beschäftigungsart',
	'zeitintensität',
	'zeitarbeit',
	'companyName',
	'tätigkeitsbereich',
	'skill',
	'benefit',
]

const isCategoryDisabled = cat =>
	!cat ||
	!cat.filters ||
	cat.filters.length === 0 ||
	cat.filters.reduce((total, { numberOfJobOffers }) => total + numberOfJobOffers, 0) ===
		0

const getCategoryFilters = cat => (cat && cat.filters) || []

const Filter = ({ categories = [], queryFilters, query, lang }) => {
	const categoriesByIdentifier = categories.reduce((prev, cat) => {
		prev[cat.identifier] = cat
		return prev
	}, {})
	return (
		<div className="filter-container">
			<FilterMenu
				lang={lang}
				entries={[
					{
						id: 'position',
						title: categoryNames.position,
						disabled: isCategoryDisabled(categoriesByIdentifier.position),
					},
					{ id: 'location', title: 'Städte', disabled: !query.loc },
					{
						id: 'contract',
						title: 'Vertrag',
						disabled:
							isCategoryDisabled(
								categoriesByIdentifier['beschäftigungsart'],
							) &&
							isCategoryDisabled(
								categoriesByIdentifier['zeitintensität'],
							) &&
							isCategoryDisabled(categoriesByIdentifier['zeitarbeit']),
					},
					{
						id: 'company',
						title: categoryNames.companyName,
						disabled: isCategoryDisabled(categoriesByIdentifier.companyName),
					},
					{
						id: 'field',
						title: categoryNames['tätigkeitsbereich'],
						disabled: isCategoryDisabled(
							categoriesByIdentifier['tätigkeitsbereich'],
						),
					},
					{
						id: 'skill',
						title: categoryNames.skill,
						disabled: isCategoryDisabled(categoriesByIdentifier.skill),
					},
					{
						id: 'benefit',
						title: categoryNames.benefit,
						disabled: isCategoryDisabled(categoriesByIdentifier.benefit),
					},
				]}
			/>
			<figure>
				<CategoryTab
					lang={lang}
					tabId="position"
					filters={getCategoryFilters(categoriesByIdentifier.position)}
					queryFilters={queryFilters}
					query={query}
				/>
				<CategoryTab
					lang={lang}
					tabId="company"
					filters={getCategoryFilters(categoriesByIdentifier.companyName)}
					queryFilters={queryFilters}
					query={query}
				/>
				<CategoryTab
					lang={lang}
					tabId="field"
					filters={getCategoryFilters(
						categoriesByIdentifier['tätigkeitsbereich'],
					)}
					queryFilters={queryFilters}
					query={query}
				/>
				<CategoryTab
					lang={lang}
					tabId="skill"
					filters={getCategoryFilters(categoriesByIdentifier.skill)}
					queryFilters={queryFilters}
					query={query}
				/>
				<CategoryTab
					lang={lang}
					tabId="benefit"
					filters={getCategoryFilters(categoriesByIdentifier.benefit)}
					queryFilters={queryFilters}
					query={query}
				/>
				<CategoryTab
					lang={lang}
					tabId="location"
					filters={getCategoryFilters(categoriesByIdentifier.standort)}
					queryFilters={queryFilters}
					query={query}
				/>
				<ContractTab
					lang={lang}
					tabId="contract"
					queryFilters={queryFilters}
					query={query}
					typeFilters={getCategoryFilters(
						categoriesByIdentifier['beschäftigungsart'],
					)}
					timeFilters={getCategoryFilters(
						categoriesByIdentifier['zeitintensität'],
					)}
					tempWorkFilters={getCategoryFilters(
						categoriesByIdentifier['zeitarbeit'],
					)}
				/>
			</figure>
			{queryFilters.length > 0 ? (
				<MyFilters
					query={query}
					queryFilters={queryFilters}
					categoriesByIdentifier={categoriesByIdentifier || {}}
					categories={myFilterCategories}
					categoryNames={categoryNames}
				/>
			) : null}
			<style jsx>{`
				figure {
					display: block;
					margin-top: -1px;
					background: white;
					border-top: 1px solid rgb(232, 232, 232);
					border-bottom: 1px solid rgb(232, 232, 232);
				}
				div {
					max-width: ${maxWidth}px;
					margin: auto;
					padding-bottom: 20px;
				}
			`}</style>
		</div>
	)
}

export default Filter
