import React from 'react'
import Link from 'next/link'
import { SearchPageQueryParams, IFilter } from 'shared/interfaces'
import { FilterPill } from './FilterPills'
import CategoryHeader from './CategoryHeader'
import { extendQuery } from 'shared/Util'
import translate from 'config/translate'

type Props = {
	queryFilters: string[]
	query: SearchPageQueryParams
	categoriesByIdentifier: {
		[category: string]: {
			displayName: string
			identifier: string
			filters: IFilter[]
		}
	}
	categories: string[]
	categoryNames: { [key: string]: string }
	lang: string
}

export const ClearPill: React.FC<{
	query: SearchPageQueryParams
	num: number
}> = ({ query, num }) => (
	<>
		<Link
			scroll={false}
			href={{
				pathname: '/search',
				query: extendQuery(query, { pf: [] }),
			}}
		>
			<a>
				{num}
				<i>+</i>
			</a>
		</Link>
		<style jsx>{`
			a,
			a:visited {
				color: rgb(187, 17, 17);
			}
			a {
				display: flex;
				align-items: center;
				text-decoration: none;
				padding: 0 10px;
				font-size: 12px;
				height: 26px;
				border-radius: 15px;
				border: 1px solid rgb(222, 222, 222);
				margin-right: 12px;
				margin-bottom: 10px;
			}
			i {
				color: black;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: -5px;
				height: 20px;
				width: 20px;
				font-size: 15px;
				font-style: normal;
				transform: rotate(45deg);
			}
		`}</style>
	</>
)

const isActive = (queryFilters: string[]) => (filter: IFilter) =>
	queryFilters.indexOf(filter.searchParameters) > -1 ||
	queryFilters.indexOf(filter.invertedSearchParameters || '') > -1

const getActiveCategoryFilters = (
	categoriesByIdentifier: Props['categoriesByIdentifier'],
	cat: string,
	queryFilters: string[],
) => {
	if (categoriesByIdentifier[cat] && categoriesByIdentifier[cat].filters) {
		return categoriesByIdentifier[cat].filters.filter(isActive(queryFilters))
	}

	return []
}

const MyFilters: React.FC<Props> = ({
	query,
	queryFilters,
	categoriesByIdentifier,
	categories,
	categoryNames,
	lang,
}) => (
	<>
		<nav>
			<h4>{translate('Meine Filter', lang)}</h4>
			<ClearPill num={queryFilters.length} query={query} />
			{categories
				.map(cat => ({
					activeFilters: getActiveCategoryFilters(
						categoriesByIdentifier,
						cat,
						queryFilters,
					),
					cat,
				}))
				.filter(({ activeFilters }) => activeFilters.length > 0)
				.map(({ cat, activeFilters }) => (
					<React.Fragment key={cat}>
						<CategoryHeader>
							{translate(categoryNames[cat], lang)}
						</CategoryHeader>
						{activeFilters.map(f => (
							<FilterPill
								{...(f as any)}
								key={f.displayName}
								queryFilters={queryFilters}
								query={query}
							/>
						))}
					</React.Fragment>
				))}
		</nav>
		<style jsx>{`
			nav {
				padding: 10px;
				padding-bottom: 2px;
				background: white;
				position: relative;
				border-bottom: 1px solid rgb(232, 232, 232);
				display: flex;
				overflow-x: scroll;
			}
			@media (min-width: 768px) {
				nav {
					padding: 20px;
					padding-bottom: 12px;
					overflow-x: initial;
					flex-wrap: wrap;
				}

				nav:before {
					content: '';
					display: block;
					width: 19px;
					height: 2px;
					background: white;
					z-index: 1;
					position: absolute;
					top: -1px;
					left: 0px;
				}
				nav:after {
					content: '';
					display: block;
					width: 19px;
					height: 2px;
					background: white;
					z-index: 1;
					position: absolute;
					top: -1px;
					right: 0px;
				}
			}
			h4 {
				font-weight: 500;
				font-size: 14px;
				margin-right: 20px;
				color: rgb(85, 85, 85);
				display: inline-block;
				white-space: nowrap;
				padding-top: 5px;
				text-transform: uppercase;
			}
			@media (hover: none) {
				div {
					max-height: 50px;
					border: none;
				}
				nav {
					overflow-x: scroll;
					-webkit-overflow-scrolling: touch;
					flex-wrap: nowrap;
					padding: 20px 10px;
					padding-bottom: 10px;
				}
				.more,
				.fill {
					display: none;
				}
			}

			@media (hover: none) and (max-height: 700px) {
				div {
					min-height: 50px;
				}
				nav {
					padding: 10px;
					padding-bottom: 0px;
				}
			}
		`}</style>
	</>
)

export default MyFilters
