import { SearchPageProps } from 'shared/interfaces'
import BottomBarSplitRight from './BottomBarSplitRight'
import Filter from './filter'
import Footer from './Footer'
import Header from './Header'
import { isNoted } from './JobList'
import Job from './jobOffer/Job'
import SearchForm from './SearchForm'
import { SplitView, SplitRight } from './SplitView'
import SearchSplitLeft from './SearchSplitLeft'
import Container from './Container'
import { accentColor } from 'config/style'

export const Search: React.FC<SearchPageProps> = ({
	searchQuery: query,
	queryFilters,
	searchResults,
	selectedJob,
	similarJobs,
	topJobs,
	notedOffers,
	page,
	host,
	isUserLoggedIn,
	asPath,
	user,
	hasNotificationForCurrentSearch,
}) => {
	const { filter } = searchResults

	return (
		<>
			<Header
				isUserLoggedIn={isUserLoggedIn}
				redirectPath={asPath}
				count={notedOffers ? notedOffers.length : 0}
				asPath={asPath}
			>
				<SearchForm
					search={query.q}
					key={JSON.stringify(query)}
					location={query.loc}
					radius={query.r ? parseInt(query.r) : 10}
					name="search-form-desktop"
					lang="de"
				/>
			</Header>
			<Container>
				<SearchForm
					className="search-form-mobile"
					name="search-form-mobile"
					search={query.q}
					key={JSON.stringify(query)}
					location={query.loc}
					radius={query.r ? parseInt(query.r) : 10}
					lang="de"
				/>
				<Filter {...filter} queryFilters={queryFilters} query={query} lang="de" />
				<SplitView>
					<SearchSplitLeft
						asPath={asPath}
						hasNotificationForCurrentSearch={hasNotificationForCurrentSearch}
						notedOffers={notedOffers}
						page={page}
						searchQuery={query}
						searchResults={searchResults}
						user={user}
						showBookmarkIcon
						lang="de"
						queryFilters={queryFilters}
					/>
					{selectedJob ? (
						<SplitRight>
							<Job
								job={selectedJob}
								key={selectedJob.jobOffer.documentId}
								selected={query.selected}
								similarJobs={similarJobs}
								topJobs={topJobs}
							/>
						</SplitRight>
					) : null}
				</SplitView>

				{selectedJob ? (
					<BottomBarSplitRight
						jobOffer={selectedJob.jobOffer}
						isNoted={
							isNoted(notedOffers, selectedJob.jobOffer.documentId)
								? true
								: false
						}
						pathname="/search"
						query={query}
						host={host}
						showBookmarkIcon
						showApplyButton
						lang="de"
					/>
				) : null}
			</Container>
			<Footer />

			<style jsx>{`
				:global(.search-form-mobile) {
					padding: 10px;
					padding-bottom: 20px;
					border-bottom: 3px solid ${accentColor};
					background: white;
				}

				@media (min-width: 769px) {
					:global(.search-form-mobile) {
						display: none;
					}
				}
			`}</style>
		</>
	)
}

export default Search
