import { More } from '../Icons'

export const filterTabHeight = 70

const FilterTab = ({ children, tabId }) => (
	<div className={tabId}>
		<nav>
			{children}
			<span className="fill" />
			<span className="more">
				<More />
			</span>
		</nav>
		<style jsx>{`
			.${tabId} {
				display: none;
			}
			:global(#${tabId}:checked ~ figure) .${tabId} {
				display: block;
			}
			div {
				border: 20px solid white;
				border-right: none;
				min-height: ${filterTabHeight}px;
				max-height: ${filterTabHeight}px;
				width: 100%;
				background: #fff;
				transition: max-height 0.5s ease-out;
				overflow: hidden;
				transition-delay: 0.5s;
			}
			div:hover {
				transition-delay: 0.3s;
				max-height: 200px;
			}
			nav {
				overflow: hidden;
				outline: 40px solid transparent;
				padding-right: 40px;
				display: flex;
				flex-wrap: wrap;
				margin-bottom: -10px;
				position: relative;
			}
			.fill {
				flex-grow: 1;
				background: transparent;
				height: 20px;
				z-index: 10;
			}
			div {
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
			}
			div:hover .more {
				opacity: 0;
			}
			.more {
				position: absolute;
				top: 0px;
				right: 10px;
				width: 40px;
				z-index: 1;
				opacity: 0.2;
				transition: opacity 0.3s;
				transition-delay: 0.5s;
			}
			.fill {
				width: 30px;
				background: white;
				display: block;
				height: 30px;
				z-index: 2;
				position: relative;
			}
			.fill::after {
				position: absolute;
				top: 0;
				bottom: 0;
				right: -30px;
				background: white;
				content: '';
				display: block;
				width: 30px;
				z-index: 2;
			}

			@media (hover: none) {
				div {
					max-height: 50px;
					border: none;
				}
				nav {
					overflow-x: scroll;
					-webkit-overflow-scrolling: touch;
					flex-wrap: nowrap;
					padding: 20px 10px;
				}
				.more,
				.fill {
					display: none;
				}
			}

			@media (hover: none) and (max-height: 700px) {
				div {
					min-height: 50px;
				}
				nav {
					padding: 10px;
				}
			}
		`}</style>
	</div>
)

export default FilterTab
