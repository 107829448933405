import { accentColor } from 'config/style'
import translate from 'config/translate'

// article about CSS only tabs which are used here
// https://www.mediaevent.de/tutorial/css-tabs.html

const inputCheckedLabelSelector = id => `#${id}:checked ~ nav label[for='${id}']`
const inputDisabledLabelSelector = id => `#${id}:disabled ~ nav label[for='${id}']`

export const navHeight = 55

const FilterMenu = ({ entries, lang }) => (
	<>
		{entries.map(({ id, disabled }, index) => (
			<input
				id={id}
				key={id}
				name="tabs"
				type="radio"
				defaultChecked={index === 0}
				disabled={disabled}
			/>
		))}
		<nav>
			<h3>{translate('Suche eingrenzen', lang)}</h3>
			{entries.map(({ id, title }) => (
				<label htmlFor={id} key={id}>
					{title}
				</label>
			))}
		</nav>
		<style jsx>{`
			input {
				display: none;
			}
			nav {
				display: flex;
				height: ${navHeight}px;
				background-color: rgb(244, 243, 243);
			}
			h3,
			label {
				display: flex;
				align-items: center;
				border-right: 1px solid rgb(232, 232, 232);
				color: rgb(156, 156, 156);
				white-space: nowrap;
				-webkit-tap-highlight-color: transparent;
			}
			h3 {
				border-right: none;
				text-transform: uppercase;
				font-size: 14px;
				color: rgb(85, 85, 85);
				padding-right: 50px;
				padding-left: 20px;
				font-weight: 500;
			}
			label {
				padding: 0 16px;
				cursor: pointer;
				font-size: 14px;
			}
			label:last-child {
				border-right: none;
			}
			label:first-child {
				border-left: 1px solid rgb(232, 232, 232);
			}
			${entries.map(({ id }) => inputCheckedLabelSelector(id)).join(',')} {
				background: #ffffff;
				color: ${accentColor};
				border-right: 1px solid rgb(232, 232, 232);
				font-weight: 500;
			}
			${inputCheckedLabelSelector(entries[0].id)} {
				border-left: 1px solid rgb(232, 232, 232);
			}
			${entries.map(({ id }) => inputDisabledLabelSelector(id)).join(',')} {
				color: #c3c3c3;
				cursor: initial;
			}
			@media (max-width: 768px) {
				nav {
					overflow-x: scroll;
					-webkit-overflow-scrolling: touch;
				}
				h3 {
					display: none;
				}
			}
			@media (max-height: 700px) {
				nav {
					height: 40px;
				}
				label {
					padding: 0 10px;
				}
			}
		`}</style>
	</>
)

export default FilterMenu
