import Link from 'next/link'
import { SearchPageProps, SearchPageQueryParams } from 'shared/interfaces'
import JobList from './JobList'
import { extendQuery } from 'shared/Util'
import { CreateJobAlert, CreateJobAlertAnonymous } from './CreateJobAlert'
import { SplitLeft } from './SplitView'
import { pageSize } from 'shared/api'
import { accentColor } from 'config/style'
import translate from 'config/translate'

export const SplitViewLeft: React.FC<
	Pick<
		SearchPageProps,
		| 'searchQuery'
		| 'searchResults'
		| 'notedOffers'
		| 'page'
		| 'asPath'
		| 'user'
		| 'hasNotificationForCurrentSearch'
		| 'queryFilters'
	> & { showBookmarkIcon: boolean; lang: string }
> = ({
	searchQuery,
	searchResults,
	notedOffers,
	page,
	asPath,
	user,
	hasNotificationForCurrentSearch,
	showBookmarkIcon,
	lang,
	queryFilters,
}) => {
	const { jobOffers, resultSize } = searchResults
	if (resultSize === 0) {
		return (
			<p>{translate('Wir haben leider keine Ergebnisse für deine Suche.', lang)}</p>
		)
	}

	return (
		<SplitLeft>
			<SearchResultInfo
				location={searchQuery.loc}
				resultSize={resultSize}
				searchQuery={searchQuery}
				queryFilters={queryFilters}
			/>
			<JobList
				jobs={jobOffers.slice(0, 6)}
				notedOffers={notedOffers}
				query={{
					...searchQuery,
					sgid: searchResults.searchGroupId,
					sid: searchResults.searchId,
				}}
				showBookmarkIcon={showBookmarkIcon}
				lang={lang}
			/>
			{user ? (
				<CreateJobAlert
					key={JSON.stringify([
						searchQuery.loc,
						searchQuery.q,
						searchQuery.r,
						searchQuery.pf,
					])}
					email={user.email}
					query={searchQuery}
					hasNotification={hasNotificationForCurrentSearch}
					lang={lang}
				/>
			) : (
				<CreateJobAlertAnonymous redirectPath={asPath} lang={lang} />
			)}
			<JobList
				jobs={jobOffers.slice(6)}
				notedOffers={notedOffers}
				query={{
					...searchQuery,
					sgid: searchResults.searchGroupId,
					sid: searchResults.searchId,
				}}
				showBookmarkIcon={showBookmarkIcon}
				lang={lang}
			/>
			{(page + 1) * pageSize < resultSize ? (
				<Link
					href={{
						pathname: '/search',
						query: extendQuery(searchQuery, {
							page: page + 1,
							sgid: searchResults.searchGroupId,
							sid: searchResults.searchId,
						}),
					}}
					scroll={false}
				>
					<a rel="nofollow" className="more">
						{translate('Weitere Jobs laden', lang)}
					</a>
				</Link>
			) : null}
			<style jsx>{`
				.more {
					padding: 0px 20px;
					margin: 20px auto;
					max-width: 200px;
					height: 40px;
					display: block;
					text-decoration: none;
					color: white;
					background: ${accentColor};
					font-size: 16px;
					line-height: 40px;
					text-align: center;
					border-radius: 5px;
					margin-bottom: 20px;
				}
				.more:visited {
					color: white;
				}
				.result-count {
					display: inline-block;
					font-size: 16px;
					font-weight: 600;
					margin-right: 5px;
					padding-left: 20px;
				}
				.result-count-title {
					color: gray;
					display: inline-block;
					margin-bottom: 10px;
				}
				@media (max-width: 768px) {
					.result-count {
						padding-left: 10px;
					}
				}
			`}</style>
		</SplitLeft>
	)
}

export const SearchResultInfo: React.FC<{
	location?: string
	searchQuery: SearchPageQueryParams
	queryFilters: readonly string[]
	resultSize: number
}> = ({ location, resultSize, searchQuery, queryFilters }) => {
	const hasLocationFilter =
		queryFilters !== undefined
			? queryFilters.filter(elem => {
					return elem.startsWith('standort')
			  }).length > 0
			: false

	return (
		<>
			<span className="result-count">{resultSize}</span>
			<span className="result-count-title">
				{searchQuery.q ? `${searchQuery.q} ` : ''}Jobs
				{!hasLocationFilter && location ? ` in ${location}` : ''}
				{!hasLocationFilter && location && searchQuery.r && searchQuery.r !== '0'
					? ` (${searchQuery.r}km)`
					: ''}
			</span>
			<style jsx>{`
				.result-count {
					display: inline-block;
					font-size: 16px;
					font-weight: 600;
					margin-right: 5px;
					padding-left: 20px;
				}
				.result-count-title {
					color: gray;
					display: inline-block;
					margin-bottom: 10px;
				}
				@media (max-width: 768px) {
					.result-count {
						padding-left: 10px;
					}
				}
			`}</style>
		</>
	)
}

export default SplitViewLeft
