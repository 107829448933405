import { primaryColor } from './styles'

const PremiumBadge = () => (
	<>
		<span>Premium</span>
		<style jsx>{`
			span {
				align-items: center;
				background: ${primaryColor};
				color: white;
				display: inline-flex;
				font-size: 9px;
				font-weight: 500px;
				height: 16px;
				padding: 0 4px;
				position: absolute;
				right: -1px;
				text-transform: uppercase;
				top: 4px;
			}

			span:before {
				border-bottom: solid 8px transparent;
				border-right: solid 8px ${primaryColor};
				border-top: solid 8px transparent;
				content: '';
				left: -8px;
				position: absolute;
			}
		`}</style>
	</>
)

export default PremiumBadge
