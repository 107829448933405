import FilterTab from './FilterTab'
import CategoryHeader from './CategoryHeader'
import { FilterPill, ResetFilterPill } from './FilterPills'
import categoryNames from './categoryNames'

const ContractTab = ({
	tabId,
	typeFilters,
	timeFilters,
	tempWorkFilters,
	queryFilters,
	query,
	lang,
}) => (
	<FilterTab tabId={tabId}>
		{typeFilters.length > 0 ? (
			<>
				<CategoryHeader>{categoryNames['beschäftigungsart']}</CategoryHeader>
				<ResetFilterPill
					lang={lang}
					queryFilters={queryFilters}
					filters={typeFilters}
					query={query}
				/>
				{typeFilters.map(filter => (
					<FilterPill
						key={filter.displayName}
						{...filter}
						queryFilters={queryFilters}
						query={query}
					/>
				))}
			</>
		) : null}
		{timeFilters.length > 0 ? (
			<>
				<CategoryHeader>{categoryNames['zeitintensität']}</CategoryHeader>
				<ResetFilterPill
					lang={lang}
					queryFilters={queryFilters}
					filters={timeFilters}
					query={query}
				/>
				{timeFilters.map(filter => (
					<FilterPill
						key={filter.displayName}
						{...filter}
						queryFilters={queryFilters}
						query={query}
					/>
				))}
			</>
		) : null}
		{tempWorkFilters.length > 0 ? (
			<>
				<CategoryHeader>{categoryNames.zeitarbeit}</CategoryHeader>
				<ResetFilterPill
					lang={lang}
					queryFilters={queryFilters}
					filters={tempWorkFilters}
					query={query}
				/>
				{tempWorkFilters.map(filter => (
					<FilterPill
						key={filter.displayName}
						{...filter}
						queryFilters={queryFilters}
						query={query}
					/>
				))}
			</>
		) : null}
	</FilterTab>
)

export default ContractTab
