import { useState, useRef, useEffect, useCallback } from 'react'
import Link from 'next/link'
import { isNoted } from './JobList'
import {
	IJobOffer,
	INotedOffer,
	SearchPageQueryParams,
	BookmarkPageQueryParams,
} from 'shared/interfaces'
import { extendQuery } from 'shared/Util'
import Heart from 'shared/icons/002-herz.svg'
import HeartFilled from 'shared/icons/026-herz-voll.svg'
import Form from './Form'
import PremiumBadge from './PremiumBadge'
import { accentColor } from 'config/style'
import ListItemHead from './ListItemHead'
import { encode } from 'querystring'

// Optimistic updates
const BookmarkSubmitButton: React.FC<{ isNoted: boolean; key: string }> = ({
	isNoted,
}) => {
	const [isNotedOptimistic, setIsNotedOptimistic] = useState(isNoted)
	const buttonRef = useRef<HTMLButtonElement>(null)
	const onClick = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			if (isNotedOptimistic === isNoted) {
				event.stopPropagation()
				event.preventDefault()
				setIsNotedOptimistic(!isNotedOptimistic)
			}
		},
		[isNoted, isNotedOptimistic],
	)
	useEffect(() => {
		if (isNotedOptimistic !== isNoted) {
			if (buttonRef.current) {
				buttonRef.current.click()
			}
		}
	}, [isNoted, isNotedOptimistic])
	return (
		<>
			<button
				type="submit"
				className={isNotedOptimistic ? 'is-noted' : ''}
				onClick={onClick}
				ref={buttonRef}
			>
				{isNotedOptimistic ? <HeartFilled /> : <Heart />}
			</button>
			<style jsx>{`
				button {
					-webkit-tap-highlight-color: transparent;
					border: none;
					position: absolute;
					bottom: 0px;
					right: 0px;
					padding: 18px;
					background: transparent;
					width: 54px;
					cursor: pointer;
					outline: none;
					fill: rgb(85, 85, 85);
				}

				button.is-noted {
					fill: #b11110;
				}
			`}</style>
		</>
	)
}

const JobListItem: React.FC<
	Readonly<{
		notedOffers: Readonly<INotedOffer[]>
		isMobile: boolean
		showBookmarkIcon: boolean
		lang: string
		query: SearchPageQueryParams | BookmarkPageQueryParams
	}> &
		IJobOffer
> = props => {
	const {
		documentId,
		features,
		notedOffers,
		isMobile,
		query,
		showBookmarkIcon,
		lang,
	} = props
	return (
		<>
			<Link
				href={
					isMobile
						? {
								pathname: '/job',
								query: {
									id: documentId,
									back: `/search?${encode(query)}`,
								},
						  }
						: {
								pathname: '/search',
								query: extendQuery(query, { selected: documentId }),
						  }
				}
				scroll={isMobile}
			>
				<a
					rel="nofollow"
					className={query.selected === documentId ? 'selected' : ''}
				>
					{features && features.isSponsored ? <PremiumBadge /> : null}
					<ListItemHead {...props} lang={lang} />
				</a>
			</Link>
			{showBookmarkIcon ? (
				<Form
					aria-label="Bookmark Form"
					method="post"
					action="/mutation/set-bookmark"
					redirectUrl={{
						pathname: '/search',
						query,
					}}
					hiddenInputs={{
						id: documentId,
						set: isNoted(notedOffers, documentId) ? 0 : 1,
					}}
				>
					<BookmarkSubmitButton
						isNoted={isNoted(notedOffers, documentId) ? true : false}
						key={isNoted(notedOffers, documentId) ? '0' : '1'}
					/>
				</Form>
			) : null}
			<style jsx>{`
				a {
					display: block;
					position: relative;
					border: 1px solid rgb(233, 232, 232);
					padding: 10px;
					text-decoration: none;
				}
				a.selected {
					box-shadow: inset 5px 0px 0px 0px ${accentColor};
				}
				a {
					padding: 20px 10px;
				}
				@media (min-width: 769px) {
					a.selected,
					a:hover {
						border: 1px solid ${accentColor};
					}
					a {
						padding: 20px;
					}

					a:focus {
						outline: none;
					}

					a:focus:not(.selected) {
						border-left: 3px solid ${accentColor};
					}
					a.selected::after {
						content: '';
						display: block;
						position: absolute;
						right: -1px;
						top: 50%;
						height: 16px;
						width: 16px;
						background: white;
						border-top: 1px solid ${accentColor};
						border-right: 1px solid ${accentColor};
						transform: translate(50%, -50%) rotate(45deg);
						z-index: 2;
					}
				}
			`}</style>
		</>
	)
}

export default JobListItem
