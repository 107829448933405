import { NextPage } from 'next'
import Head from 'next/head'
import searchView from 'shared/views/search'
import { useIsMobile, useTrackPageImpression } from 'shared/customHooks'
import { SearchPageProps as Props } from 'shared/interfaces'
import Search from 'shared/components/Search'
import ApplicationForm from 'shared/components/applicationForm/ApplicationForm'
import { getJobWebsiteTitle, getSearchWebsiteTitle } from 'shared/Util'

const SearchPage: NextPage<Props> = props => {
	const { asPath, query, selectedJob } = props

	// Hooks-Aufrufe dürfen nicht innerhalb von conditions verwendet werden,
	// darum muss zunächst isMobile explizit definiert werden
	const isMobile = useIsMobile()

	const title = selectedJob
		? isMobile
			? getSearchWebsiteTitle(query)
			: getJobWebsiteTitle(selectedJob)
		: getSearchWebsiteTitle(query)

	useTrackPageImpression(title, asPath, query)

	return (
		<>
			<Head>
				<title>{title}</title>
				<meta name="Description" content={`Suchergebnisse fuer ${query.q}`} />
			</Head>
			<Search {...props} />
			<ApplicationForm job={selectedJob} lang="de" />
		</>
	)
}

SearchPage.getInitialProps = async ctx => {
	const props = await searchView(ctx)
	return props
}

export default SearchPage
