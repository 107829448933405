import { sendApplicationForm } from 'shared/api'
import Pin from 'shared/icons/011-pin.svg'
import RightArrow from 'shared/icons/009-pfeil-pager-rechts.svg'
import X from 'shared/icons/015-X-tag.svg'
import translate from 'config/translate'
import { IApplicationFormResponse, IJobOfferDetails } from 'shared/interfaces'
import React from 'react'
import Recaptcha from 'react-google-recaptcha'
import Dialogbox, { IDialogboxContent } from '../Dialogbox'
import Dropzone from './Dropzone'
import Modal from '../Modal'
import { accentColor, thirdAccentColor, secondaryAccentColor } from 'config/style'

interface IApplicationFormControlsCollection extends HTMLFormControlsCollection {
	email: HTMLInputElement
	'email-company': HTMLInputElement
	'first-name': HTMLInputElement
	'last-name': HTMLInputElement
	legal: HTMLInputElement
	message: HTMLTextAreaElement
}

interface IErrorMessages {
	email: string
	emailCompany: string
	firstName: string
	lastName: string
	legal: string
}

type Props = Readonly<{
	job?: IJobOfferDetails
	lang: string
}>

type State = Readonly<{
	closeCount: number
	dialogboxContent: IDialogboxContent
	errorMessages: IErrorMessages
	isSendButtonEnabled: boolean
	uploadCount: number
}>

class ApplicationForm extends React.PureComponent<Props, State> {
	private _formReference = React.createRef<HTMLFormElement>()
	private _recaptchaReference = React.createRef<Recaptcha>()

	constructor(props: Props) {
		super(props)
		this.state = this.getInitialState()
	}

	public componentDidUpdate(prevProps: Props, prevState: State) {
		const { state } = this

		if (prevProps && state.closeCount !== prevState.closeCount) {
			this.setState({
				...this.getInitialState(),
				closeCount: this.state.closeCount,
			})
		}
	}

	public render() {
		const { job, lang } = this.props

		if (!job) {
			return null
		}

		const {
			closeCount,
			dialogboxContent,
			errorMessages,
			isSendButtonEnabled,
		} = this.state
		const {
			companyName,
			jobApplicationContact,
			location,
			logoUrl,
			title,
		} = job.jobOffer
		const recaptchaKey = process.env.RECAPTCHA_KEY || ''
		const optionalLabel = translate('(Optional)', lang)

		const emailCompany = !jobApplicationContact
			? undefined
			: jobApplicationContact.email
			? jobApplicationContact.email
			: jobApplicationContact.extractedEmail
			? jobApplicationContact.extractedEmail
			: undefined

		return (
			<>
				<Modal id="application-form" isScrollable isLazy>
					<div className="box">
						<button
							className="close-button"
							onClick={() => this.closeApplicationForm()}
						>
							<X />
						</button>
						<h3>{translate('Deine Bewerbung', lang)}</h3>
						<div className="company-info">
							<div>
								{logoUrl ? <img src={logoUrl} /> : null}
								<div>
									<h2>{companyName}</h2>
									<h1>{title}</h1>
									<p>
										<span className="icon">
											<Pin />
										</span>
										{location}
									</p>
								</div>
							</div>
						</div>
						<form
							ref={this._formReference}
							onSubmit={event => this.submitForm(event, lang)}
						>
							<div className="name-row">
								<div>
									<label>
										<p>{translate('Vorname', lang)}</p>
										<input
											className={
												errorMessages.firstName.length > 0
													? 'error-frame'
													: undefined
											}
											name="first-name"
											placeholder={translate('z.B. Peter', lang)}
											type="text"
										/>
									</label>
									<p className="error-message">
										{errorMessages.firstName}
									</p>
								</div>
								<div>
									<label>
										<p>{translate('Nachname', lang)}</p>
										<input
											className={
												errorMessages.lastName.length > 0
													? 'error-frame'
													: undefined
											}
											name="last-name"
											placeholder={translate('z.B. Müller', lang)}
											type="text"
										/>
									</label>
									<p className="error-message">
										{errorMessages.lastName}
									</p>
								</div>
							</div>
							<label>
								<p>{translate('E-Mail-Adresse', lang)}</p>
								<input
									className={
										errorMessages.email.length > 0
											? 'error-frame'
											: undefined
									}
									name="email"
									placeholder={translate(
										'z.B. petermueller@meinprovider.de',
										lang,
									)}
									type="email"
								/>
							</label>
							<p className="error-message">{errorMessages.email}</p>
							{emailCompany ? (
								<input
									name="email-company"
									type="hidden"
									value={emailCompany}
								/>
							) : (
								<>
									<label>
										<p>
											{translate(
												'E-Mail-Adresse des Unternehmens',
												lang,
											)}
										</p>
										<input
											className={
												errorMessages.emailCompany.length > 0
													? 'error-frame'
													: undefined
											}
											name="email-company"
											placeholder={translate(
												'z.B. schmidt@unternehmensname.com',
												lang,
											)}
											type="email"
										/>
									</label>
									<p className="error-message">
										{errorMessages.emailCompany}
									</p>
								</>
							)}
							<div className="dropzone-area">
								<p className="dropzone-headline optional">
									{translate('Dokumente', lang)}
								</p>
								<Dropzone
									closeCount={closeCount}
									lang={lang}
									onUploadChange={(
										isSendButtonEnabled: boolean,
										uploadCount: number,
									) =>
										this.setState({
											isSendButtonEnabled,
											uploadCount,
										})
									}
								/>
							</div>
							<label>
								<p className="optional">
									{translate('Anschreiben', lang)}
								</p>
								<textarea name="message" rows={6} />
							</label>
							<div className="legal-area">
								<div className="legal-checkbox-block">
									<input
										name="legal"
										id="legal-checkbox"
										type="checkbox"
									/>
									<div>
										<span className="legal-text">
											<label htmlFor="legal-checkbox">
												{translate(
													'Hiermit akzeptiere ich die',
													lang,
												)}{' '}
											</label>
											<a
												href="https://www.kimeta.de/Content/Downloads/Datenschutzerklaerung.pdf"
												target="_blank"
											>
												{translate('Datenschutzerklärung', lang)}
											</a>
											<label htmlFor="legal-checkbox">
												{' '}
												{translate(
													'und erkläre mich gemäß Ziffer 6 der Datenschutzerklärung mit der Verarbeitung meiner Bewerberdaten einverstanden.',
													lang,
												)}
											</label>
										</span>
										<p className="error-message">
											{errorMessages.legal}
										</p>
									</div>
								</div>
								<div className="recaptcha">
									<Recaptcha
										badge="inline"
										ref={this._recaptchaReference}
										sitekey={recaptchaKey}
										size="invisible"
										onChange={token => this.sendForm(token, lang)}
									/>
								</div>
							</div>
							<div className="button-row">
								<button
									onClick={() => this.closeApplicationForm()}
									type="reset"
								>
									<span>
										<RightArrow />
									</span>
									{translate('Abbrechen', lang)}
								</button>
								<button
									disabled={!isSendButtonEnabled}
									name="submit"
									type="submit"
								>
									{translate('Bewerbung senden', lang)}
								</button>
							</div>
						</form>
					</div>
					<style jsx>{`
						a {
							color: ${thirdAccentColor};
							text-decoration: none;
						}

						button {
							outline: none;
						}

						h1 {
							font-size: 16px;
							font-size: 600;
							font-weight: bold;
							padding-top: 8px;
						}

						h2 {
							color: ${secondaryAccentColor};
							font-size: 14px;
						}

						h3 {
							font-size: 19px;
							font-weight: 600;
						}

						img {
							width: 76px;
						}

						input[type='checkbox'] {
							background-color: #f4f4f4;
							border: 1px solid #e2e2e2;
							cursor: pointer;
							margin-top: 2px;
							outline: none;
							min-width: 30px;
						}

						input[type='email'],
						input[type='text'],
						textarea {
							box-sizing: border-box;
							background-color: #fff;
							border: 1px solid #e2e2e2;
							border-radius: 5px;
							font-size: 15px;
							outline: none;
							padding: 0 2px 0 4px;
							width: 100%;
							appearance: none;
						}

						input[type='email'],
						input[type='text'] {
							height: 33px;
						}

						input::placeholder {
							color: #9e9c9c;
							font-style: italic;
						}

						input[type='email'].error-frame,
						input[type='text'].error-frame {
							border-color: #b11111;
						}

						label p,
						.dropzone-headline {
							font-size: 17px;
							font-weight: 600;
							margin-bottom: 10px;
							margin-top: 20px;
							user-select: none;
						}

						textarea {
							resize: none;
						}

						.box {
							background-color: #fff;
							border: 1px solid #e2e2e2;
							border-top: 3px solid ${accentColor};
							margin: 10px auto;
							min-width: 300px;
							max-width: 800px;
							padding: 20px;
							position: relative;
							width: 95%;
						}

						.button-row {
							align-items: center;
							border-top: 1px solid #e5e5e5;
							display: flex;
							justify-content: space-between;
							margin-bottom: 10px;
							margin-top: 30px;
							outline: none;
							padding-top: 20px;
						}

						.button-row button {
							border: none;
							cursor: pointer;
						}

						.button-row button:first-of-type {
							background-color: #fff;
							color: ${thirdAccentColor};
							display: flex;
							font-size: 14px;
						}

						.button-row button:first-of-type:before {
							font-size: 16px;
						}

						.button-row button:first-of-type span {
							display: inline-block;
							height: 14px;
							width: 14px;
						}

						.button-row :global(path) {
							fill: ${accentColor};
						}

						.button-row button:last-child {
							background-color: ${accentColor};
							border-radius: 4px;
							box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
							color: #fff;
							font-size: 16px;
							margin-left: 10px;
							padding: 10px 15px;
							text-decoration: none;
						}

						.button-row button:last-child:disabled {
							background-color: #e5e5e5;
						}

						.close-button {
							background-color: #fff;
							border: none;
							color: #9e9c9c;
							cursor: pointer;
							height: 38px;
							margin-top: -3px;
							padding: 5px;
							position: absolute;
							right: 10px;
							top: 18px;
							white-space: nowrap;
							width: 38px;
						}

						.company-info {
							background-color: #fafafa;
							border: 1px solid #e2e2e2;
							margin: 20px 0 10px;
							padding: 20px;
						}

						.company-info > div {
							align-items: center;
							margin-bottom: 20px;
						}

						.company-info > div > div {
							hyphens: auto;
							overflow-wrap: break-word;
							word-break: break-word;
						}

						.company-info i {
							font-size: 14px;
						}

						.company-info p {
							color: #555;
							display: flex;
							font-size: 12px;
							align-items: center;
						}

						.dropzone-area {
							margin-top: 7px;
							padding-bottom: 7px;
						}

						.error-message {
							color: red;
							font-size: 12px;
							margin-top: 6px;
						}

						.icon {
							padding-right: 3px;
							padding-top: 2px;
							width: 12px;
						}
						.icon :global(path) {
							fill: rgb(85, 85, 85);
						}

						.legal-area {
							margin-top: 20px;
						}

						.legal-checkbox-block label {
							margin-right: 2px;
							margin-top: 2px;
							-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
						}

						.legal-checkbox-block:first-of-type {
							display: flex;
						}

						.legal-text {
							font-size: 14px;
							font-style: italic;
							line-height: 20px;
							user-select: none;
						}

						.optional::after {
							color: #9c9c9c;
							content: '${optionalLabel}';
							font-size: 13px;
							font-style: italic;
							margin-left: 6px;
						}

						.recaptcha {
							height: 60px;
							width: 256px;
						}

						@media (max-width: 769px) {
							img {
								margin-bottom: 10px;
							}

							.company-info > div {
								text-align: center;
							}

							.company-info p {
								display: flex;
								justify-content: center;
							}

							.recaptcha div {
								margin: 10px auto 0;
							}
						}

						@media only screen and (min-width: 576px) {
							.name-row {
								display: flex;
								flex-wrap: wrap;
							}

							.name-row div {
								flex-grow: 1;
							}

							.name-row div:first-of-type {
								margin-right: 10px;
							}

							.name-row div:last-child {
								margin-left: 10px;
							}
						}

						@media (min-width: 769px) {
							h1 {
								font-size: 22px;
							}

							h2 {
								font-size: 16px;
							}

							img {
								margin-right: 10px;
							}

							.company-info > div {
								display: flex;
							}

							.legal-area {
								display: flex;
							}

							.legal-checkbox-block:last-child {
								margin: 1px 1px 0 3px;
							}

							.recaptcha {
								margin: 4px 0 0 5px;
							}
						}
					`}</style>
				</Modal>
				<Dialogbox
					id="applicationform"
					message={dialogboxContent.message}
					negative={dialogboxContent.negative}
					positive={dialogboxContent.positive}
				/>
			</>
		)
	}

	private closeApplicationForm = () => {
		const applicationFormTrigger = document.getElementById(
			'modal-application-form',
		) as HTMLInputElement | null

		if (!applicationFormTrigger) {
			return false
		}

		if (this._formReference.current) {
			this._formReference.current.reset()
		}

		this.setState({ closeCount: this.state.closeCount + 1 })
		applicationFormTrigger.checked = false
		return true
	}

	private executeRecaptcha(lang: string) {
		if (!this._recaptchaReference.current) {
			this.showDialogboxWithDefaultError(lang)
			return false
		}

		this._recaptchaReference.current.execute()
		return true
	}

	private getInitialState(): State {
		return {
			closeCount: 0,
			dialogboxContent: {
				message: '',
				positive: { label: '' },
			},
			errorMessages: {
				email: '',
				emailCompany: '',
				firstName: '',
				lastName: '',
				legal: '',
			},
			isSendButtonEnabled: true,
			uploadCount: 0,
		}
	}

	private handleSendFormResponse(response: IApplicationFormResponse, lang: string) {
		let dialogboxContent: IDialogboxContent | undefined

		if (response.Success) {
			dialogboxContent = {
				message: translate(
					'Deine Bewerbung wurde erfolgreich versendet. Du erhälst in Kürze noch eine Bestätigung per Mail.',
					lang,
				),
				positive: {
					label: translate('OK', lang),
					callback: () => this.closeApplicationForm(),
				},
			}
		} else if (response.Error) {
			switch (response.Error) {
				case 'applicantNotFound':
					dialogboxContent = {
						message: translate(
							'Der Zeitraum zum Hochladen wurde überschritten. Bitte fülle das Formular erneut aus und lade Deine bereits hochgeladenen Dokumente erneut hoch.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
							callback: () => this.closeApplicationForm(),
						},
					}
					break
				case 'badRequest':
					dialogboxContent = {
						message: translate(
							'Es ist ein Kommunikationsfehler mit dem Server aufgetreten. Bitte versuche es später erneut.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
							callback: () => this.closeApplicationForm(),
						},
					}
					break
				case 'captchaRejected':
					dialogboxContent = {
						message: translate(
							'Das ReCaptcha wurde abgelehnt. Bitte fülle das Formular erneut aus und versuche es nochmal.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
							callback: () => this.closeApplicationForm(),
						},
					}
					break
				case 'formFieldInvalid':
					dialogboxContent = {
						message: translate(
							'Mindestens eines der Eingabefelder wurde nicht ordnungsgemäß ausgefüllt.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
						},
					}
					break
				case 'jobOfferExpired':
					dialogboxContent = {
						message: translate(
							'Das Stellenangebot wurde aus dem System entfernt. Somit kann Deine Bewerbung leider nicht mehr versendet werden.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
							callback: () => this.closeApplicationForm(),
						},
					}
					break
				case 'offerNotFound':
					dialogboxContent = {
						message: translate(
							'Das Stellenangebot konnte leider nicht gefunden werden. Bitte versuche es später erneut.',
							lang,
						),
						positive: {
							label: translate('OK', lang),
						},
					}
					break
				default:
					break
			}
		}

		if (!dialogboxContent) {
			return this.showDialogboxWithDefaultError(lang)
		}

		this.showDialogbox(dialogboxContent)
		return dialogboxContent
	}

	private async sendForm(captchaToken: string | null, lang: string) {
		const applicationId = getApplicationId()

		if (!applicationId || !captchaToken || !this._formReference.current) {
			return this.showDialogboxWithDefaultError(lang)
		}

		const elements = this._formReference.current
			.elements as IApplicationFormControlsCollection

		const showSpinnerOverlayEvent = document.createEvent('Event')
		showSpinnerOverlayEvent.initEvent('showSpinnerOverlay', true, true)
		document.dispatchEvent(showSpinnerOverlayEvent)

		const response = await sendApplicationForm({
			applicationId,
			captchaToken,
			email: elements.email.value,
			emailCompany: elements['email-company']
				? elements['email-company'].value
				: '',
			firstName: elements['first-name'].value,
			lastName: elements['last-name'].value,
			legal: elements.legal.checked,
			message: elements.message.value,
		})

		const hideSpinnerOverlayEvent = document.createEvent('Event')
		hideSpinnerOverlayEvent.initEvent('hideSpinnerOverlay', true, true)
		document.dispatchEvent(hideSpinnerOverlayEvent)

		return this.handleSendFormResponse(response, lang)
	}

	private showDialogbox(dialogboxContent: IDialogboxContent) {
		const dialogboxTrigger = document.getElementById(
			'modal-dialogbox-applicationform',
		) as HTMLInputElement | null

		if (dialogboxTrigger) {
			this.setState({ dialogboxContent })
			dialogboxTrigger.checked = true
		}
	}

	private showDialogboxWithDefaultError(lang: string) {
		const dialogboxContent = {
			message: translate(
				'Beim Versenden Deiner Bewerbung ist leider ein Fehler aufgetreten.',
				lang,
			),
			positive: { label: translate('OK', lang) },
		}

		this.showDialogbox(dialogboxContent)
		return dialogboxContent
	}

	private submitForm(event: React.FormEvent<HTMLFormElement>, lang: string) {
		event.preventDefault()

		if (!this.validateForm(lang)) {
			return false
		}

		const { uploadCount } = this.state

		if (uploadCount > 0) {
			this.executeRecaptcha(lang)
			return true
		}

		const dialogboxContent = {
			message: translate(
				'Es wurden keine Dokumente auf den Server hochgeladen. Bist Du sicher, dass Du keine Dokumente hinzufügen möchtest?',
				lang,
			),
			positive: {
				label: translate('Ja', lang),
				callback: () => this.executeRecaptcha(lang),
			},
			negative: { label: translate('Nein', lang) },
		}

		this.showDialogbox(dialogboxContent)
		return dialogboxContent
	}

	private validateForm(lang: string) {
		if (!this._formReference.current) {
			return false
		}

		const elements = this._formReference.current
			.elements as IApplicationFormControlsCollection
		let isValid = true
		const regExEmail = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
		const errorMessages: IErrorMessages = {
			email: '',
			emailCompany: '',
			firstName: '',
			lastName: '',
			legal: '',
		}

		if (!regExEmail.test(elements.email.value)) {
			errorMessages.email = translate(
				'Bitte gebe eine gültige E-Mail-Adresse ein.',
				lang,
			)
			isValid = false
		}

		if (
			elements['email-company'].type !== 'hidden' &&
			!regExEmail.test(elements['email-company'].value)
		) {
			errorMessages.emailCompany = translate(
				'Bitte gebe eine gültige E-Mail-Adresse ein.',
				lang,
			)
			isValid = false
		}

		if (elements['first-name'].value.length < 1) {
			errorMessages.firstName = translate('Bitte gebe einen Vornamen ein.', lang)
			isValid = false
		}

		if (elements['last-name'].value.length < 1) {
			errorMessages.lastName = translate('Bitte gebe einen Nachnamen ein.', lang)
			isValid = false
		}

		if (!elements.legal.checked) {
			errorMessages.legal = translate(
				'Bitte bestätige die Datenschutzerklärung.',
				lang,
			)
			isValid = false
		}

		this.setState({ errorMessages })
		return isValid
	}
}

export default ApplicationForm

export const getApplicationId = () => {
	const applicationFormTrigger = document.getElementById(
		'modal-application-form',
	) as HTMLInputElement | null

	if (!applicationFormTrigger || applicationFormTrigger.value.length < 4) {
		return undefined
	}

	return applicationFormTrigger.value
}
