import React, { useRef, useCallback, useEffect, useState } from 'react'
import Spinner from '../Spinner'

const iFrameFallbackHeight = 5000

type Props = Readonly<{
	src?: string
}>
const IFrame: React.FC<Props> = ({ src }) => {
	const ref = useRef<HTMLIFrameElement>(null)
	const [isLoaded, setIsLoaded] = useState(false)

	const setHeight = useCallback(() => {
		const iFrame = ref.current
		setIsLoaded(true)

		if (!iFrame || !src) {
			return
		}

		try {
			iFrame.height = getIFrameInnerHeight(iFrame)
			iFrame.scrolling = 'no'
		} catch (error) {
			iFrame.height = `${iFrameFallbackHeight}px`
		}
	}, [])

	useEffect(() => {
		const interval = setInterval(setHeight, 2000)
		window.addEventListener('resize', setHeight)
		return () => {
			clearInterval(interval)
			window.removeEventListener('resize', setHeight)
		}
	}, [])

	return (
		<>
			<iframe
				key={src}
				height="0"
				src={src}
				ref={ref}
				onLoad={setHeight}
				width="100%"
				frameBorder="0"
				scrolling="yes"
				style={{
					visibility: isLoaded ? 'visible' : 'hidden',
					overflow: 'scroll',
					width: '1px',
					minWidth: '100%',
				}}
			/>
			{!isLoaded ? (
				<div className="spinner-container">
					<Spinner />
				</div>
			) : null}
			<style jsx>{`
				.spinner-container {
					height: 300px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			`}</style>
		</>
	)
}

export default IFrame

const getIFrameInnerHeight = (iFrame: HTMLIFrameElement): string => {
	// http://www.dyn-web.com/tutorials/iframes/height/
	// https://stackoverflow.com/questions/1145850/
	if (
		!iFrame.contentWindow ||
		!iFrame.contentWindow.document ||
		!iFrame.contentWindow.document.body
	) {
		throw new Error(`could access iframe body (src=${iFrame.src})`)
	}

	const { scrollHeight, offsetHeight } = iFrame.contentWindow.document.body
	return Math.max(scrollHeight, offsetHeight) + 'px'
}
