import Link from 'next/link'
import { UrlObject } from 'url'
import Modal from './Modal'
import { accentColor } from 'config/style'

export interface IDialogButtonContent {
	href?: Readonly<UrlObject>
	isShallow?: boolean
	label: string
	callback?: () => void // nur clientseitig bei aktiviertem JavaScript ausführbar
}

export interface IDialogboxContent {
	message: string
	negative?: IDialogButtonContent
	positive: IDialogButtonContent
}

type Props = Readonly<
	{
		id: string
	} & IDialogboxContent
>

const Dialogbox: React.FC<Props> = ({ id, message, negative, positive }) => {
	return (
		<Modal id={`dialogbox-${id}`} zIndex={101}>
			<div className="box">
				<div>{message}</div>
				<div className="button-row">
					{negative ? (
						negative.href ? (
							<Link href={negative.href} shallow={negative.isShallow}>
								<a className="negative" rel="nofollow">
									{negative.label}
								</a>
							</Link>
						) : (
							<label
								className="negative"
								htmlFor={`modal-dialogbox-${id}`}
								onClick={() => negative.callback && negative.callback()}
							>
								{negative.label}
							</label>
						)
					) : null}
					{positive.href ? (
						<Link href={positive.href} shallow={positive.isShallow}>
							<a className="positive" rel="nofollow">
								{positive.label}
							</a>
						</Link>
					) : (
						<label
							className="positive"
							htmlFor={`modal-dialogbox-${id}`}
							onClick={() => positive.callback && positive.callback()}
						>
							{positive.label}
						</label>
					)}
				</div>
			</div>
			<style jsx>{`
				.box {
					background-color: #fff;
					border-radius: 2px;
					font-size: 15px;
					font-weight: 600;
					line-height: 20px;
					margin: 40vh auto;
					padding: 15px 25px;
					user-select: none;
					width: 300px;
				}

				.button-row {
					display: flex;
					justify-content: space-around;
					margin-bottom: 7px;
					margin-top: 13px;
				}

				.negative,
				.positive {
					background-color: #fff;
					border: 1px solid #e5e5e5;
					border-radius: 5px;
					cursor: pointer;
					text-decoration: none;
					padding: 5px;
					text-align: center;
					width: 100px;
				}

				.positive {
					background-color: ${accentColor};
					border: 1px solid ${accentColor};
					color: #fff;
				}

				@media (min-width: 769px) {
					.box {
						width: 360px;
					}

					.negative,
					.positive {
						width: 120px;
					}
				}
			`}</style>
		</Modal>
	)
}

export default Dialogbox
