import FilterTab from './FilterTab'
import { FilterPill, ResetFilterPill } from './FilterPills'

const CategoryTab = ({ filters, tabId, query, queryFilters, lang }) => (
	<FilterTab tabId={tabId}>
		<ResetFilterPill
			queryFilters={queryFilters}
			filters={filters}
			query={query}
			lang={lang}
		/>
		{filters.map(filter => (
			<FilterPill
				key={filter.displayName}
				{...filter}
				query={query}
				queryFilters={queryFilters}
			/>
		))}
	</FilterTab>
)

export default CategoryTab
