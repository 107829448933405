import { requestApplicationId } from 'shared/api'
import {
	IJobOffer,
	SearchPageQueryParams,
	BookmarkPageQueryParams,
} from 'shared/interfaces'
import { isIpad2OrLower, isIphone4OrLower } from 'shared/userAgentParser'
import PdfDownloadButton from './PdfDownloadButton'
import Heart from 'shared/icons/026-herz-voll.svg'
import External from 'shared/icons/056-vergrößern.svg'
import Share from 'shared/icons/027-teilen.svg'
import Facebook from 'shared/icons/058-facebook.svg'
import Twitter from 'shared/icons/057-twitter.svg'
import LinkedIn from 'shared/icons/064-linkedin.svg'
import Telegram from 'shared/icons/062-telegram.svg'
import WhatsApp from 'shared/icons/063-whatsapp.svg'
import Mail from 'shared/icons/065-mail.svg'
import Form from './Form'
import { accentColor } from 'config/style'
import translate from 'config/translate'
import { encode } from 'querystring'
import LoginToApply from 'config/components/LoginToApply'

export type Props = Readonly<{
	jobOffer: IJobOffer
	isNoted: boolean
	pathname: string
	query: SearchPageQueryParams | BookmarkPageQueryParams
	isStandAlone?: boolean
	host: string
	showBookmarkIcon: boolean
	showApplyButton: boolean
	lang: string
	forceCompanyEmail?: boolean
}>

export const ShareBar: React.FC<{
	url: string
	title: string
	isStandAlone: boolean
}> = ({ url, title, isStandAlone }) => {
	return (
		<>
			<div className={isStandAlone ? 'share-bar standalone' : 'share-bar'}>
				<a
					href={`https://www.facebook.com/sharer/sharer.php?${encode({
						u: url,
						quote: title,
					})}`}
					target="_blank"
				>
					<Facebook />
				</a>
				<a
					href={`https://www.linkedin.com/shareArticle?url=${url}`}
					target="_blank"
				>
					<LinkedIn />
				</a>
				<a
					href={`https://twitter.com/share?${encode({ url, text: title })}`}
					target="_blank"
				>
					<Twitter />
				</a>
				<a
					href={`https://telegram.me/share/url?${encode({ url, text: title })}`}
					target="_blank"
				>
					<Telegram />
				</a>
				<a
					href={`https://api.whatsapp.com/send?${encode({ url, text: title })}`}
					target="_blank"
				>
					<WhatsApp />
				</a>
				<a href={`mailto:?subject=${title}&body=${url}`} target="_blank">
					<Mail />
				</a>
			</div>

			<style jsx>{`
				.share-bar {
					z-index: 3;
					align-items: center;
					display: flex;
					flex-grow: 1;
					justify-content: space-between;
					padding: 0 20px;
					height: 48px;
					background: white;
				}
				.share-bar.standalone {
					max-width: 100%;
				}

				.share-bar a {
					width: 30px;
					height: 30px;
					cursor: pointer;
					flex-grow: 0;
					flex-shrink: 0;
					margin-right: 10px;
				}

				@media (max-width: 768px) {
					.share-bar {
						max-width: 100%;
						padding: 0 10px;
					}
				}
				@media print {
					.share-bar {
						display: none;
					}
				}
			`}</style>
		</>
	)
}
const isIFrame = (jobOffer: IJobOffer) =>
	(jobOffer.features && jobOffer.features.displayType === 'IFrameWithMetadata') ||
	(jobOffer.features && jobOffer.features.displayType === 'IFrameWithoutMetadata')

const BottomBar: React.FC<Props> = ({
	jobOffer,
	isNoted,
	pathname,
	query,
	isStandAlone,
	showApplyButton,
	showBookmarkIcon,
	host,
	lang,
	forceCompanyEmail,
}) => {
	const { link, target } = getApplicationLinkAndTarget(jobOffer, forceCompanyEmail)
	const useJavaScript = target === '_self' && !isIpad2OrLower() && !isIphone4OrLower()
	const downloadUrl = `/api/job-pdf?${encode({
		documentId: jobOffer.documentId,
		documentTitle: jobOffer.title,
		location: jobOffer.location || ' ',
		lang,
	})}`

	return (
		<div className="container">
			<input id="share-toggle" type="checkbox" />
			<div className={isStandAlone ? 'bottombar standalone' : 'bottombar'}>
				{!isIFrame(jobOffer) ? (
					<PdfDownloadButton downloadUrl={downloadUrl} />
				) : null}
				{showBookmarkIcon ? (
					<div className={isNoted ? 'icon heart filled' : 'icon heart'}>
						<Form
							action="/mutation/set-bookmark"
							method="post"
							redirectUrl={{ pathname, query }}
							hiddenInputs={{
								id: jobOffer.documentId,
								set: isNoted ? 0 : 1,
							}}
						>
							<button id="bookmark-button" type="submit"></button>
						</Form>
						<label htmlFor="bookmark-button">
							<Heart />
						</label>
					</div>
				) : null}

				<div className="icon">
					<label htmlFor="share-toggle">
						<Share />
					</label>
				</div>
				<a
					className="icon"
					href={
						jobOffer.offerUrl
							? jobOffer.offerUrl
							: `/job?id=${jobOffer.documentId}`
					}
					target="_blank"
				>
					<External />
				</a>
				{showApplyButton ? (
					<a
						className="apply"
						href={link}
						rel="nofollow"
						target={target}
						onClick={
							useJavaScript
								? event =>
										requestApplicationForm(
											event,
											jobOffer,
											forceCompanyEmail,
										)
								: undefined
						} // Bewerberformular ist nur dann verwendbar, wenn JavaScript nutzbar ist
					>
						{translate('Jetzt bewerben', lang)}
					</a>
				) : (
					<LoginToApply
						lang={lang}
						redirectPath={`${pathname}?${encode(query)}`}
					/>
				)}
			</div>
			<ShareBar
				url={`${host}/job?id=${jobOffer.documentId}`}
				title={jobOffer.title}
				isStandAlone={isStandAlone ? isStandAlone : false}
			/>
			<style jsx>{`
				.container {
					overflow: hidden;
					height: 96px;
					margin-top: -48px;
					pointer-events: none;
				}
				.bottombar {
					z-index: 3;
					border-top: 1px solid rgb(244, 242, 243);
					box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px -2px;
					align-items: center;
					display: flex;
					flex-grow: 1;

					justify-content: space-between;
					padding: 0 20px;
					background: ${accentColor};
					height: 48px;
				}
				.bottombar.standalone {
					max-width: 100%;
				}

				:global(.share-bar),
				.bottombar {
					transform: translateY(48px);
					transition: transform 0.3s;
					pointer-events: all;
				}

				:global(#share-toggle:checked ~ .share-bar),
				#share-toggle:checked ~ .bottombar {
					transform: translateY(0px);
				}
				input {
					display: none;
				}
				label {
					cursor: pointer;
				}

				#bookmark-button {
					display: none;
				}
				.icon {
					width: 30px;
					height: 30px;
					cursor: pointer;
					flex-grow: 0;
					flex-shrink: 0;
					margin-right: 10px;
				}

				.heart {
					border: none;
					outline: none;
					background: ${accentColor};
				}

				:global(.icon:hover path) {
					fill: rgba(255, 255, 255, 0.7);
				}

				:global(.heart:hover > svg) {
					fill: rgb(177, 17, 17);
				}

				:global(.heart.filled path) {
					fill: rgb(177, 17, 17);
					stroke: rgb(177, 17, 17);
				}

				:global(.icon path) {
					fill: white;
					stroke: white;
				}

				.apply {
					background: white;
					color: black;
					padding: 6px 0;
					border-radius: 5px;
					text-decoration: none;
					text-align: center;
					flex-grow: 1;
					max-width: 50%;
					white-space: nowrap;
				}

				.apply:hover {
					background-color: rgb(244, 243, 243);
				}

				@media print {
					.bottombar {
						display: none;
					}
				}

				@media (max-width: 768px) {
					.bottombar {
						max-width: 100%;
						padding: 0 10px;
					}
				}
			`}</style>
		</div>
	)
}
export default BottomBar

export const getApplicationLinkAndTarget = (
	jobOffer: IJobOffer,
	forceCompanyEmail?: boolean,
) => {
	const { jobApplicationContact } = jobOffer
	let link: string | undefined
	let target = ''

	if (jobApplicationContact) {
		if (jobApplicationContact.email && jobApplicationContact.email.length > 0) {
			link = `mailto:${jobApplicationContact.email}`
			target = '_self'
		} else if (
			jobApplicationContact.extractedEmail &&
			jobApplicationContact.extractedEmail.length > 0
		) {
			link = `mailto:${jobApplicationContact.extractedEmail}`
			target = '_self'
		} else if (forceCompanyEmail) {
			link = `mailto:`
			target = '_self'
		} else if (
			jobApplicationContact.applicationUrl &&
			jobApplicationContact.applicationUrl.length > 0
		) {
			link = jobApplicationContact.applicationUrl
			target = '_blank'
		}
	}

	if (!link) {
		link = jobOffer.offerUrl
		target = '_blank'
	}

	return { link, target }
}

export const requestApplicationForm = async (
	event: React.SyntheticEvent,
	jobOffer: IJobOffer,
	forceCompanyEmail?: boolean,
) => {
	const applicationFormTrigger = document.getElementById(
		'modal-application-form',
	) as HTMLInputElement | null

	if (!applicationFormTrigger) {
		// Wird der Trigger nicht gefunden, so wird anstelle des Bewerberformulars
		// die Mailto-Funktion des Browsers bzw. der Link aufgerufen, da
		// preventDefault noch nicht ausgeführt wurde
		return 'no-trigger'
	}

	event.preventDefault()
	applicationFormTrigger.value = ''

	const showSpinnerOverlayEvent = document.createEvent('Event')
	showSpinnerOverlayEvent.initEvent('showSpinnerOverlay', true, true)
	document.dispatchEvent(showSpinnerOverlayEvent)

	const response = await requestApplicationId(jobOffer.documentId)

	const hideSpinnerOverlayEvent = document.createEvent('Event')
	hideSpinnerOverlayEvent.initEvent('hideSpinnerOverlay', true, true)
	document.dispatchEvent(hideSpinnerOverlayEvent)

	if (
		response &&
		response.Success &&
		response.Payload &&
		response.Payload.ApplicationId
	) {
		applicationFormTrigger.value = response.Payload.ApplicationId
		applicationFormTrigger.click()
		applicationFormTrigger.checked = true
		return 'triggered'
	}

	// Sollte der Serverzugriff scheitern, so wird anstelle des Bewerberformulars
	// die Mailto-Funktion des Browsers bzw. der Link aufgerufen
	const { link, target } = getApplicationLinkAndTarget(jobOffer, forceCompanyEmail)
	const windowReference = window.open(link, target)

	// Fallback falls Popup-Blocking aktiviert
	if (!windowReference && target === '_blank') {
		window.open(link, '_self')
	}

	return 'fallback'
}
