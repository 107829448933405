import Link from 'next/link'
import { extendQuery } from 'shared/Util'
import translate from 'config/translate'

const isFilterActive = (queryFilters, searchParameters, invertedSearchParameters) =>
	queryFilters.indexOf(searchParameters) > -1 ||
	queryFilters.indexOf(invertedSearchParameters) > -1

const hrefWithSearchParameter = (query, queryFilters, searchParameters) => ({
	pathname: '/search', //router.pathname,
	query: extendQuery(query, {
		page: 0,
		pf: queryFilters.concat(searchParameters),
	}),
})

const hrefWithoutSearchParameter = (query, queryFilters, searchParameters) => ({
	pathname: '/search', //router.pathname,
	query: extendQuery(query, {
		page: 0,
		pf: queryFilters.filter(f => f !== searchParameters),
	}),
})

export const FilterPill = ({
	query,
	queryFilters,
	displayName,
	numberOfJobOffers,
	searchParameters,
	isInvertedSearchable,
	invertedSearchParameters,
}) => (
	<li
		className={
			isFilterActive(queryFilters, searchParameters)
				? 'positive'
				: isFilterActive(queryFilters, invertedSearchParameters)
				? 'negative'
				: ''
		}
	>
		{isInvertedSearchable && !isFilterActive(queryFilters, searchParameters) ? (
			numberOfJobOffers > 0 ? (
				<Link
					scroll={false}
					href={
						isFilterActive(queryFilters, invertedSearchParameters)
							? hrefWithoutSearchParameter(
									query,
									queryFilters,
									invertedSearchParameters,
							  )
							: hrefWithSearchParameter(
									query,
									queryFilters,
									invertedSearchParameters,
							  )
					}
				>
					<a rel="nofollow">
						{isFilterActive(queryFilters, invertedSearchParameters) ? (
							<i className="remove">+</i>
						) : (
							<i>-</i>
						)}
					</a>
				</Link>
			) : (
				<i>-</i>
			)
		) : null}
		<span>
			{displayName} ({numberOfJobOffers})
		</span>
		{!isFilterActive(queryFilters, invertedSearchParameters) ? (
			<Link
				scroll={false}
				href={
					isFilterActive(queryFilters, searchParameters)
						? hrefWithoutSearchParameter(
								query,
								queryFilters,
								searchParameters,
						  )
						: hrefWithSearchParameter(query, queryFilters, searchParameters)
				}
			>
				<a rel="nofollow">
					{isFilterActive(queryFilters, searchParameters) ? (
						<i className="remove">+</i>
					) : (
						<i>+</i>
					)}
				</a>
			</Link>
		) : null}
		<style jsx>{`
			span {
				padding: 0 2px;
			}
			span:first-child {
				padding-left: 26px;
			}
			span:last-child {
				padding-right: 26px;
			}
			.remove {
				transform: rotate(45deg);
			}
			i {
				width: 26px;
				height: 26px;
				display: flex;
				align-items: center;
				justify-content: center;
				font-style: normal;
				font-size: 15px;
				border-radius: 50%;
				padding-bottom: 1px;
			}
			a {
				color: black;
				text-decoration: none;
				-webkit-tap-highlight-color: transparent;
			}
			a::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
			}
			a:hover i {
				background: rgb(210, 208, 209);
			}
			a:first-child::after {
				left: 0;
				right: 50%;
			}
			a:last-child::after {
				right: 0;
				left: 50%;
			}
			li {
				white-space: nowrap;
				height: 28px;
				background: #e5e5e5;
				border-radius: 15px;
				border: 1px solid rgb(222, 222, 222);
				font-size: 13px;
				margin-bottom: 10px;
				margin-right: 12px;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			li.positive {
				background: #f2f6d9;
			}
			li.positive a:hover i {
				background: rgb(224, 236, 163);
			}
			li.negative {
				background: rgb(249, 230, 232);
				border-color: rgb(177, 17, 17);
			}
			li.negative a:hover i {
				background: rgb(240, 194, 197);
			}
		`}</style>
		<style jsx>{`
			li {
				opacity: ${numberOfJobOffers === 0 ? '0.4' : '1.0'};
			}
		`}</style>
	</li>
)

const isSomeFilterActive = (queryFilters, filters) =>
	filters.some(({ searchParameters, invertedSearchParameters }) =>
		isFilterActive(queryFilters, searchParameters, invertedSearchParameters),
	)

const queryFiltersWithoutFilters = (queryFilters, filters) =>
	queryFilters.filter(
		qf =>
			!filters.find(
				f => f.searchParameters === qf || f.invertedSearchParameters === qf,
			),
	)

const hrefWithoutAllFilters = (query, queryFilters, filters) => ({
	pathname: '/search',
	query: extendQuery(query, {
		pf: queryFiltersWithoutFilters(queryFilters, filters),
	}),
})

export const ResetFilterPill = ({ query, filters, queryFilters, lang }) => (
	<li className={isSomeFilterActive(queryFilters, filters) ? '' : 'disabled'}>
		{!isSomeFilterActive(queryFilters, filters) ? (
			<span>{translate('Alle', lang)}</span>
		) : (
			<Link
				href={hrefWithoutAllFilters(query, queryFilters, filters)}
				scroll={false}
			>
				<a rel="nofollow">{translate('Alle', lang)}</a>
			</Link>
		)}
		<style jsx>{`
			a,
			span {
				display: block;
				padding: 0 10px;
				color: black;
				text-decoration: none;
				-webkit-tap-highlight-color: transparent;
			}
			span {
				background: rgb(229, 229, 229);
				color: gray;
			}
			li {
				height: 28px;
				background: #e5e5e5;
				border-radius: 15px;
				border: 1px solid rgb(222, 222, 222);
				font-size: 13px;
				margin-bottom: 10px;
				margin-right: 12px;
				display: flex;
				align-items: center;
			}
			li.disabled {
				opacity: 0.4;
			}
		`}</style>
	</li>
)
