import Form, { requestSubmit } from './Form'
import AutoCompleteInput from './AutoCompleteInput'
import DistanceSelect from './DistanceSelect'
import ActionButton from './ActionButton'
import { useState } from 'react'
import { getAutoCompleteJob, getAutoCompleteLocation } from 'shared/api'
import { maxWidth } from 'shared/components/Container'
import translate from 'config/translate'

type Props = Readonly<{
	name: string
	location?: string
	radius: number
	search?: string
	className?: string
	lang: string
}>

const SearchForm: React.FC<Props> = ({
	search,
	location,
	radius,
	className,
	name,
	lang,
}) => {
	const [isLoading, setIsLoading] = useState(false)

	return (
		<div className={className}>
			<Form
				action="/search"
				method="get"
				className="search-form"
				name={name}
				onSubmit={event => {
					const form: HTMLFormElement = event.target as HTMLFormElement
					if (
						form['q'].value !== search ||
						form['loc'].value !== location ||
						form['r'].value !== radius.toString()
					) {
						setIsLoading(true)
					}
				}}
			>
				<label htmlFor="search">Suche</label>
				<span className="input job">
					<AutoCompleteInput
						type="search"
						name="q"
						defaultValue={search}
						placeholder={translate('Jobtitel oder Stichwort', lang)}
						id="search"
						list="ac-jobs"
						getAutoComplete={
							lang === 'de' ? getAutoCompleteJob : async () => []
						}
					/>
				</span>
				<span className="input location">
					<AutoCompleteInput
						type="search"
						name="loc"
						placeholder={translate('Ort oder PLZ', lang)}
						defaultValue={location}
						getAutoComplete={
							lang === 'de' ? getAutoCompleteLocation : async () => []
						}
					>
						<DistanceSelect
							radius={radius}
							onChange={() => requestSubmit(name)}
						/>
					</AutoCompleteInput>
				</span>
				<span className="button">
					<ActionButton type="submit" isLoading={isLoading} fat>
						{translate('Jobs finden', lang)}
					</ActionButton>
				</span>
				<style jsx>{`
					:global(.search-form) {
						background: white;
						position: relative;
						display: flex;
						flex-grow: 1;
						flex-wrap: wrap;
						max-width: ${maxWidth}px;
						margin: auto;
					}
					.location :placeholder-shown ~ span {
						display: none;
					}
					label {
						padding-right: 20px;
						display: none;
					}

					.input {
						margin-right: 10px;
						min-width: 100px;
						flex-shrink: 1;
						flex-grow: 1;
						width: 30%;
					}

					@media (min-width: 769px) {
						.button {
							min-width: 80px;
							max-width: 180px;
							flex-shrink: 1;
							flex-grow: 1;
						}
						.input {
							max-width: 365px;
						}
					}

					@media (max-width: 768px) {
						.input {
							margin: 0px;
						}
						.input.job {
							width: 100%;
							margin-bottom: 10px;
							margin-right: 0px;
							margin-top: 10px;
						}
						.input.location {
							width: 60%;
						}
						.input,
						.button {
							flex-grow: 1;
							outline: none;
						}
						.button {
							height: 40px;
							max-width: 100%;
							min-width: 100%;
							margin-top: 10px;
						}
					}
				`}</style>
			</Form>
		</div>
	)
}

export default SearchForm
