import BackButton from './BackButton'
import { accentColor } from 'config/style'

export const JobContainer: React.FC<{
	standAlone: boolean
	lang: string
	isSelected: boolean
	backUrl?: string
}> = ({ standAlone, children, lang, isSelected, backUrl }) => (
	<div
		className={`job${standAlone || isSelected ? '' : ' invisible'}${
			standAlone ? ' stand-alone' : ''
		}`}
	>
		<div className="overflow">
			{backUrl ? <BackButton lang={lang} backUrl={backUrl} /> : null}
			{children}
		</div>
		<style jsx>{`
			.job {
				position: relative;
				height: 100%;
				overflow-y: scroll;
				-webkit-overflow-scrolling: touch;
				line-height: 1.5;
				font-size: 14px;
				z-index: 2;
				border-top: 3px solid ${accentColor};
			}

			.job:not(.job.stand-alone) .overflow {
				height: 100%;
				overflow: hidden;
				overflow-y: scroll;
			}

			.job.stand-alone {
				border-top: none;
				position: static;
				height: auto;
				height: initial;
				overflow-y: initial;
			}

			@supports (position: sticky) {
				:not(.stand-alone).overflow {
					height: initial;
					overflow: initial;
					overflow-y: initial;
				}
			}

			@media (max-width: 768px) {
				.job {
					border: none;
				}
				.job.invisible {
					transform: translateX(110%);
					height: 0;
					overflow: hidden;
				}

				.job:not(.invisible) {
					background: #fafafa;
					box-shadow: none;
					position: fixed;
					height: initial;
					bottom: 30px;
					left: 0;
					right: 0;
					transform: translateX(0%);
					z-index: 2;
					pointer-events: auto;
				}

				.job.stand-alone {
					position: static;
					height: auto;
					height: initial;
				}
			}
			@media print {
				.hidden {
					display: block;
				}
				.job:not(.invisible) {
					background: white;
				}
			}
		`}</style>
		<style jsx global>{`
			@media (max-width: 768px) {
				body {
					overflow: ${isSelected ? 'hidden' : 'initial'};
				}
			}
		`}</style>
	</div>
)

export default JobContainer
