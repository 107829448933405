import Head from 'next/head'
import Link from 'next/link'
import Footer from 'shared/components/Footer'
import Header from 'shared/components/Header'
import SeoLink from 'shared/components/SeoLink'
import { useTrackPageImpression } from 'shared/customHooks'
import { maxWidth } from 'shared/components/Container'
import {
	BrowsingPageProps,
	SeoBrowsingBlock,
	SeoLinkDescription,
} from 'shared/interfaces'

export const LinkList: React.FC<{ links: SeoLinkDescription[] }> = ({ links }) => (
	<ul>
		{links.map((el, index) => (
			<li key={index}>
				<SeoLink seoLink={el} />
			</li>
		))}
		<style jsx>{`
			ul {
				list-style: none;
				display: flex;
				flex-wrap: wrap;
				padding: 20px 0;
			}

			@media (min-width: 769px) {
				ul {
					padding: 40px 0;
				}
			}

			li {
				font-size: 15px;
				flex-grow: 1;
				padding-right: 10px;
				min-width: 99%;
			}
			@media (min-width: 600px) {
				li {
					min-width: 50%;
					max-width: 50%;
				}
			}

			@media (min-width: 769px) {
				li {
					min-width: 32%;
					max-width: 33%;
				}
			}

			@media (min-width: 1060px) {
				li {
					min-width: 25%;
					max-width: 25%;
				}
			}

			li :global(a) {
				font-weight: normal;
				padding: 2px 0;
				display: inline-block;
				color: rgb(90, 120, 29);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
			}

			li :global(a strong) {
				font-weight: 500;
			}
		`}</style>
	</ul>
)

export const BrowsingBlock: React.FC<SeoBrowsingBlock> = ({
	BlockTitle,
	_browsingElements,
}) => (
	<>
		<h3 id={BlockTitle}>{BlockTitle}</h3>
		<LinkList links={_browsingElements.map(({ Link }) => Link)} />
		<style jsx>{`
			h3 {
				padding: 10px 0;
			}
		`}</style>
	</>
)

const BrowsingPage: React.FC<BrowsingPageProps> = ({
	seoBrowsingPage,
	notedOffers,
	asPath,
	isUserLoggedIn,
	query,
}) => {
	useTrackPageImpression(seoBrowsingPage.Resource.PageProperties.Title, asPath, query)

	return (
		<>
			<Head>
				<title>{seoBrowsingPage.Resource.PageProperties.Title}</title>
				<meta
					name="description"
					content={seoBrowsingPage.Resource.PageProperties.Description}
				/>
				<meta
					name="keywords"
					content={seoBrowsingPage.Resource.PageProperties.Keywords}
				/>
			</Head>
			<Header
				count={notedOffers.length}
				isUserLoggedIn={isUserLoggedIn}
				redirectPath={asPath}
				asPath={asPath}
			/>
			<section>
				<h1>{seoBrowsingPage.Resource.BrowsingPageHeading}</h1>
				{seoBrowsingPage.Resource['<OnPageNavigation>k__BackingField'] ? (
					<nav>
						{seoBrowsingPage.Resource[
							'<OnPageNavigation>k__BackingField'
						].map(name => (
							<Link href={{ hash: name }} key={name}>
								<a>{name}</a>
							</Link>
						))}
					</nav>
				) : null}
				{seoBrowsingPage.Resource.LinksToBrowsingPages ? (
					<LinkList links={seoBrowsingPage.Resource.LinksToBrowsingPages} />
				) : null}
				{seoBrowsingPage.Resource.BrowsingBlock
					? seoBrowsingPage.Resource.BrowsingBlock.map(browsingBlock => (
							<BrowsingBlock
								{...browsingBlock}
								key={browsingBlock.BlockTitle}
							/>
					  ))
					: null}
				{seoBrowsingPage.Resource._computedAlphabeticBrowsingBlocks
					? seoBrowsingPage.Resource._computedAlphabeticBrowsingBlocks.map(
							browsingBlock => (
								<BrowsingBlock
									{...browsingBlock}
									key={browsingBlock.BlockTitle}
								/>
							),
					  )
					: null}
			</section>
			<Footer />
			<style jsx>{`
				:global(body) {
					background: white;
				}
				section {
					max-width: ${maxWidth}px;
					margin: 0 auto;
					padding: 0 10px;
				}
				@media (min-width: 769px) {
					section {
						padding: 0 20px;
					}
				}
				h1 {
					font-size: 17px;
					padding: 10px 0;
				}

				nav a {
					padding: 2px 10px;
					width: 30px;
					font-size: 12px;
					margin: 6px;
					color: black;
					text-decoration: none;
					border: 1px solid #c3c3c3;
					border-radius: 3px;
				}

				nav {
					display: flex;
					flex-wrap: wrap;
					margin: 0 -6px;
				}

				nav a:visited {
					color: black;
				}
			`}</style>
		</>
	)
}

export default BrowsingPage
