import Link from 'next/link'
import { ISimilarJob } from 'shared/interfaces'
import PremiumBadge from '../PremiumBadge'

export const TopJobs: React.FC<Readonly<{ jobs: readonly ISimilarJob[] }>> = ({
	jobs,
}) => (
	<div className="top-jobs">
		<div>
			<PremiumBadge />
		</div>
		<h2>Top Jobs</h2>
		<SimpleJobList jobs={jobs} />
		<style jsx>{`
			h2 {
				color: rgb(170, 204, 0);
				display: block;
				font-size: 18px;
				font-weight: 600;
				padding: 20px;
			}
			.top-jobs {
				border: 1px solid rgb(232, 232, 232);
				background-color: white;
				margin: 10px 0;
				position: relative;
				overflow: hidden;
			}
			@media (max-width: 768px) {
				h2 {
					padding: 20px 10px;
				}
				.top-jobs div {
					display: none;
				}
			}
			@media print {
				.top-jobs {
					display: none;
				}
			}
		`}</style>
	</div>
)

export const SimilarJobs: React.FC<Readonly<{ jobs: readonly ISimilarJob[] }>> = ({
	jobs,
}) => (
	<div>
		<h2>Weitere Jobs des Unternehmens</h2>
		<SimpleJobList jobs={jobs} />
		<style jsx>{`
			div {
				border: 1px solid rgb(232, 232, 232);
				background-color: white;
				margin: 10px 0;
			}
			h2 {
				font-size: 18px;
				position: relative;
				overflow: hidden;
			}
			h2,
			a {
				padding: 20px;
				font-weight: 600;
				display: block;
			}
			@media (max-width: 768px) {
				h2 {
					padding: 20px 10px;
				}
			}
			@media print {
				div {
					display: none;
				}
			}
		`}</style>
	</div>
)

const SimpleJobList: React.FC<Readonly<{ jobs: readonly ISimilarJob[] }>> = ({
	jobs,
}) => (
	<>
		<ul>
			{jobs.map(({ documentId, title }) => (
				<li key={documentId}>
					<Link href={{ pathname: '/job', query: { id: documentId } }}>
						<a rel="nofollow">{title}</a>
					</Link>
				</li>
			))}
		</ul>
		<style jsx>{`
			ul {
				list-style: none;
				background: white;
			}
			li:nth-child(odd) {
				background-color: rgb(235, 234, 233);
			}
			li {
				color: black;
				font-size: 15px;
			}
			a {
				padding: 20px;
				font-weight: 600;
				display: block;
			}
			a {
				color: inherit;
				text-decoration: none;
			}
			a:visited {
				color: #99b129;
			}
			@media (max-width: 768px) {
				a {
					padding: 20px 10px;
				}
			}
		`}</style>
	</>
)
