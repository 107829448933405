import { useIsSafariDesktop } from 'shared/customHooks'
import { maxWidth } from 'shared/components/Container'

export const SplitLeft: React.FC = ({ children }) => (
	<div className="split-left">
		{children}
		<style jsx>{`
			@media (min-width: 769px) {
				div {
					height: 100%;
					overflow: hidden;
					overflow-y: scroll;
					width: 40%;
					padding-right: 14px;
				}
			}
			@supports (position: sticky) {
				div {
					height: initial;
					overflow: initial;
					overflow-y: initial;
				}
			}
			@media (max-width: 768px) {
				div {
					height: initial;
					overflow: initial;
					overflow-y: initial;
				}
			}
		`}</style>
	</div>
)

export const SplitRight: React.FC = ({ children }) => (
	<div className="split-right">
		{children}
		<style jsx>{`
			div {
				display: none;
			}
			@media (min-width: 769px) {
				div {
					display: block;
					width: 60%;
					position: sticky;
					top: 0px;
					height: 100vh;
				}
			}
		`}</style>
	</div>
)

export const SplitView: React.FC = ({ children }) => {
	const isSafariDesktop = useIsSafariDesktop()

	return (
		<div className="split">
			{children}
			<style jsx>{`
				@media (min-width: 769px) {
					div {
						display: flex;
						max-width: ${maxWidth}px;
						margin: 0 auto;
					}
				}
				div {
					height: calc(100vh - 216px);
					overflow: hidden;
				}
				@supports (position: sticky) {
					div {
						height: initial;
						overflow: initial;
					}
				}
				@media (max-width: 768px) {
					div {
						height: initial;
						overflow: initial;
					}
				}
			`}</style>
			<style jsx global>{`
				html body,
				html {
					height: ${isSafariDesktop ? '100%' : 'initial'};
					width: ${isSafariDesktop ? '100%' : 'initial'};
					overflow: ${isSafariDesktop ? 'auto' : 'initial'};
				}
			`}</style>
		</div>
	)
}
