import { uploadApplicationFormFile, deleteApplicationFormFile } from 'shared/api'
import Upload from 'shared/icons/028-download.svg'
import X from 'shared/icons/015-X-tag.svg'
import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
const uuid: () => string = require('uuid/v4')
import translate from 'config/translate'
import { getApplicationId } from './ApplicationForm'
import DotsSpinner from '../DotsSpinner'
import { thirdAccentColor, secondaryAccentColor } from 'config/style'

export const fileStates = {
	DELETING: 'deleting',
	ERROR: 'error',
	UPLOADED: 'uploaded',
	UPLOADING: 'uploading',
}

export const maximumUploadSize = 20971520

let controller: AbortController | undefined
let signal: AbortSignal | undefined

type FileUpload = Readonly<{
	data: Readonly<File>
	errorMessageId?: string
	id: string
	status: string
}>
type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>
type SetFileListFunction = React.Dispatch<React.SetStateAction<FileUpload[]>>

type Props = Readonly<{
	closeCount: number
	lang: string
	onUploadChange: (p1: boolean, p2: number) => void
}>

const Dropzone: React.FC<Props> = ({ closeCount, lang, onUploadChange }) => {
	const [fileList, setFileList] = useState<FileUpload[]>([])

	useEffect(() => {
		// Fileliste nach jedem schließen des ApplicationForms löschen
		setFileList([])
	}, [closeCount])

	useEffect(() => {
		let count = 0
		let isUploadComplete = true

		fileList.forEach(file => {
			if (file.status === fileStates.UPLOADED) {
				count++
			} else {
				isUploadComplete = false
			}
		})

		onUploadChange(isUploadComplete, count)
	}, [fileList])

	useEffect(() => {
		controller =
			typeof AbortController !== 'undefined' ? new AbortController() : undefined
		signal = controller !== undefined ? controller.signal : undefined

		return () => {
			if (controller !== undefined) {
				controller.abort()
			}
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		accept: [
			'application/msword',
			'application/pdf',
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
			'image/gif',
			'image/jpeg',
			'image/png',
			'text/plain',
		],
		onDrop: (acceptedFileList, rejectedFileList) =>
			handleDrop(fileList, acceptedFileList, rejectedFileList, setFileList),
		maxSize: maximumUploadSize,
	})

	return (
		<>
			<div className="dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				<div>
					<Upload />
				</div>
				<p>{translate('Dateien hier hineinziehen oder', lang)}</p>
				<button type="button">{translate('Dateien auswählen', lang)}</button>
			</div>
			<p className="hint">
				{translate(
					'(max. 20 MB für Anschreiben, Lebenslauf, Zeugnisse, etc.)',
					lang,
				)}
			</p>
			<style jsx>{`
				button {
					background-color: #fff;
					border: 1px solid #9e9c9c;
					border-radius: 5px;
					color: ${thirdAccentColor};
					cursor: pointer;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					outline: none;
					padding: 10px 20px;
				}

				input {
					outline: none;
				}

				.dropzone {
					background-color: #fafafa;
					border: 1px dashed #e2e2e2;
					border-radius: 5px;
					color: #9e9c9c;
					cursor: pointer;
					font-size: 16px;
					font-style: italic;
					outline: none;
					padding: 20px;
					text-align: center;
				}

				.dropzone div {
					margin: -6px auto 20px;
					width: 34px;
				}

				.dropzone div :global(path) {
					fill: rgb(158, 156, 156);
				}

				.dropzone p {
					margin-bottom: 20px;
				}

				.hint {
					color: #9e9c9c;
					font-size: 13px;
					font-style: italic;
					margin-top: 10px;
					text-align: right;
				}

				@media (hover: none) {
					.dropzone p {
						display: none;
					}
				}
			`}</style>
			<FileList fileList={fileList} lang={lang} setFileList={setFileList} />
		</>
	)
}

export default Dropzone

type FileListProps = {
	fileList: FileUpload[]
	lang: string
	setFileList: SetFileListFunction
}

export const FileList: React.FC<FileListProps> = ({ fileList, lang, setFileList }) => {
	const [selectedTooltipFileId, setSelectedTooltipFileId] = useState<string>('')

	return fileList.length > 0 ? (
		<div>
			<p>{translate('Deine Dokumente:', lang)}</p>
			<ul>
				{fileList.map((file, index) => (
					<li key={index}>
						<span
							className={
								'file-name' +
								(file.status !== fileStates.UPLOADED ? ' incomplete' : '')
							}
						>
							{file.data.name}
						</span>{' '}
						<span
							className={
								'file-size' +
								(file.status !== fileStates.UPLOADED ? ' incomplete' : '')
							}
						>
							({Math.ceil(file.data.size / 1024)} KB)
						</span>
						<span className="filter-status-controls">
							<FileStatusControls
								errorMessageId={file.errorMessageId}
								fileId={file.id}
								lang={lang}
								selectedTooltipFileId={selectedTooltipFileId}
								status={file.status}
								setFileList={setFileList}
								setSelectedTooltipFileId={setSelectedTooltipFileId}
							/>
						</span>
					</li>
				))}
			</ul>
			<style jsx>{`
				div {
					font-size: 15px;
					margin-top: 10px;
				}

				li {
					align-items: center;
					display: flex;
					margin-top: 2px;
				}

				li:first-of-type {
					margin-top: 0;
				}

				li:before {
					background-color: #000;
					border-radius: 50%;
					content: '';
					height: 5px;
					margin-top: -1px;
					margin-right: 8px;
					min-width: 5px;
					width: 5px;
				}

				p {
					font-size: 15px;
					margin-bottom: 4px;
				}

				ul {
					font-size: 15px;
					margin-block-end: 0;
					margin-block-start: 0;
					padding-inline-start: 0;
				}

				.file-name {
					height: 24px;
					margin-right: 5px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}

				.file-size {
					height: 24px;
					margin-right: 5px;
					white-space: nowrap;
				}

				.filter-status-controls {
					height: 24px;
					position: relative;
					width: 56px;
				}

				.incomplete {
					color: #9e9c9c;
				}
			`}</style>
		</div>
	) : null
}

type FileStatusControlsProps = {
	errorMessageId?: string
	fileId: string
	lang: string
	selectedTooltipFileId: string
	status: string
	setFileList: SetFileListFunction
	setSelectedTooltipFileId: React.Dispatch<React.SetStateAction<string>>
}

export const FileStatusControls: React.FC<FileStatusControlsProps> = ({
	errorMessageId,
	fileId,
	lang,
	selectedTooltipFileId,
	status,
	setFileList,
	setSelectedTooltipFileId,
}) => {
	const list = {
		[fileStates.DELETING]: <DotsSpinner />,
		[fileStates.ERROR]: (
			<>
				{errorMessageId ? (
					<span className="info">
						<button
							className="info-button"
							onClick={() =>
								setSelectedTooltipFileId(
									fileId !== selectedTooltipFileId ? fileId : '',
								)
							}
							type="button"
						>
							!
						</button>
						{fileId === selectedTooltipFileId ? (
							<Tooltip errorMessageId={errorMessageId} lang={lang} />
						) : null}
					</span>
				) : null}
				<button
					className="action-button"
					onClick={event => removeFileFromList(fileId, setFileList, event)}
					type="button"
				>
					<X />
				</button>
				<style jsx>{`
					.action-button {
						background-color: #fff;
						border: none;
						color: #9e9c9c;
						height: 36px;
						padding: 10px;
						position: absolute;
						top: -8px;
						white-space: nowrap;
						width: 36px;
					}

					.info {
						padding-left: 5px;
						position: relative;
						top: -1px;
					}

					.info-button {
						background-color: #fff;
						border: 2px solid #b11111;
						border-radius: 50%;
						color: #b11111;
						font-size: 12px;
						font-weight: bold;
						height: 24px;
						outline: none;
						white-space: nowrap;
						width: 24px;
					}
				`}</style>
			</>
		),
		[fileStates.UPLOADED]: (
			<>
				<button
					onClick={event => deleteFileFromServer(fileId, setFileList, event)}
					type="button"
				>
					<X />
				</button>
				<style jsx>{`
					button {
						background-color: #fff;
						border: none;
						color: #9e9c9c;
						height: 26px;
						margin-top: -3px;
						padding: 5px;
						white-space: nowrap;
						width: 26px;
					}
				`}</style>
			</>
		),
		[fileStates.UPLOADING]: <DotsSpinner />,
	}

	return list[status]
}

type TooltipProps = {
	errorMessageId?: string
	lang: string
}

export const Tooltip: React.FC<TooltipProps> = ({ errorMessageId, lang }) => {
	const fileUploadMessages: { [key: string]: string } = {
		emptyFile: translate('Diese Datei ist leer.', lang),
		fileWithSameName: translate(
			'Es wurde bereits eine Datei mit diesem Namen hochgeladen. Bitte lösche diese zunächst, bevor Du es erneut versuchst.',
			lang,
		),
		generalError: translate(
			'Es ist Fehler aufgetreten. Bitte versuche es später erneut.',
			lang,
		),
		infectedFile: translate(
			'Diese Datei ist möglicherweise mit einem Virus infiziert! Bitte führe einen Virenscan durch und versuche es anschließend erneut.',
			lang,
		),
		fileSizeLimitedExceeded: translate(
			'Diese Datei überschreitet das Limit von 20 MB. Bitte reduziere die Dateigrößen.',
			lang,
		),
		typeError: translate('Die Datei hat ein ungültiges Format.', lang),
	}

	return errorMessageId !== undefined ? (
		<>
			<span>{fileUploadMessages[errorMessageId]}</span>
			<style jsx>{`
				span {
					background-color: #fff;
					border: 1px solid #e2e2e2;
					bottom: 32px;
					box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.15);
					color: ${secondaryAccentColor};
					min-width: 128px;
					padding: 10px;
					position: absolute;
					right: -39px;
				}

				span:before {
					background: #fff;
					border-bottom: 1px solid #e5e5e5;
					border-right: 1px solid #e5e5e5;
					bottom: -8px;
					content: '';
					display: block;
					height: 15px;
					position: absolute;
					right: 41px;
					transform: scale(1.3, 1) rotate(45deg);
					width: 15px;
				}
			`}</style>
		</>
	) : null
}

export const deleteFileFromServer = async (
	fileId: string,
	setFileList: SetFileListFunction,
	event: ReactMouseEvent,
) => {
	event.preventDefault()
	const applicationId = getApplicationId()

	if (!applicationId) {
		return 'noApplicationId'
	}

	setFileList(fileList =>
		fileList.map(file => {
			if (file.id === fileId) {
				return { ...file, status: fileStates.DELETING }
			}

			return file
		}),
	)

	const response = await deleteApplicationFormFile(applicationId, fileId, signal)

	if (!response) {
		handleServerResponse(fileId, setFileList, 'generalError')
		return 'generalError'
	} else if (response.Error) {
		handleServerResponse(fileId, setFileList, response.Error)
		return 'responseError'
	}

	removeFileFromList(fileId, setFileList)
	return 'done'
}

export const handleDrop = (
	uploadList: FileUpload[],
	acceptedFileList: File[],
	rejectedFileList: File[],
	setFileList: SetFileListFunction,
) => {
	const applicationId = getApplicationId()

	if (!applicationId) {
		return 'noApplicationId'
	}

	const newFileList: FileUpload[] = []

	acceptedFileList.forEach(acceptedFile => {
		const id = uuid()
		let file: FileUpload = {
			data: acceptedFile,
			id,
			status: fileStates.UPLOADING,
		}

		uploadList.forEach(item => {
			if (item.data.name === acceptedFile.name) {
				file = {
					data: acceptedFile,
					errorMessageId: 'fileWithSameName',
					id,
					status: fileStates.ERROR,
				}
			}
		})

		if (acceptedFile.size < 1) {
			file = {
				data: acceptedFile,
				errorMessageId: 'emptyFile',
				id,
				status: fileStates.ERROR,
			}
		}

		newFileList.push(file)

		if (!file.errorMessageId) {
			uploadFileToServer(applicationId, file, setFileList)
		}
	})

	rejectedFileList.forEach(rejectedFile => {
		newFileList.push({
			data: rejectedFile,
			errorMessageId:
				rejectedFile.size > maximumUploadSize
					? 'fileSizeLimitedExceeded'
					: 'typeError',
			id: uuid(),
			status: fileStates.ERROR,
		})
	})

	setFileList(fileList => [...fileList, ...newFileList])
	return 'done'
}

export const handleServerResponse = (
	fileId: string,
	setFileList: SetFileListFunction,
	errorMessageId?: string,
) => {
	setFileList(fileList =>
		fileList.map(file => {
			if (file.id === fileId) {
				return {
					...file,
					status:
						errorMessageId === undefined
							? fileStates.UPLOADED
							: fileStates.ERROR,
					errorMessageId,
				}
			}

			return file
		}),
	)
}

export const removeFileFromList = (
	fileId: string,
	setFileList: SetFileListFunction,
	event?: ReactMouseEvent,
) => {
	if (event !== undefined) {
		event.preventDefault()
	}

	setFileList(fileList => fileList.filter(({ id }) => id !== fileId))
}

export const uploadFileToServer = async (
	applicationId: string,
	file: FileUpload,
	setFileList: SetFileListFunction,
) => {
	const response = await uploadApplicationFormFile(
		applicationId,
		file.id,
		file.data,
		signal,
	)

	if (!response) {
		handleServerResponse(file.id, setFileList, 'generalError')
		return 'generalError'
	}

	handleServerResponse(file.id, setFileList, response.Error)
	return 'done'
}
