import translate from 'config/translate'
import { ICompanyInfo } from 'shared/interfaces'
import Pin from 'shared/icons/011-pin.svg'
import Group from 'shared/icons/019-gruppe.svg'
import Xing from 'shared/icons/016-xing.svg'
import LinkedIn from 'shared/icons/005-LinkedIn.svg'
import Twitter from 'shared/icons/057-twitter.svg'
import Facebook from 'shared/icons/058-facebook.svg'
import Instagramm from 'shared/icons/059-instagramm.svg'
import Pinterest from 'shared/icons/060-pinterest.svg'
import Youtube from 'shared/icons/061-youtube.svg'
import Paragraph from 'shared/icons/022-paragraph.svg'
import Factory from 'shared/icons/018-fabrik.svg'
import { linkAccentColor, secondaryAccentColor } from 'config/style'

const componentMap: { [key: string]: JSX.Element } = {
	XING: <Xing />,
	LINKEDIN: <LinkedIn />,
	TWITTER: <Twitter />,
	FACEBOOK: <Facebook />,
	INSTAGRAMM: <Instagramm />,
	PINTEREST: <Pinterest />,
	YOUTUBE: <Youtube />,
}

const CompanyInfo: React.FC<ICompanyInfo & { lang?: string }> = ({
	name,
	address = {},
	employeeMin,
	employeeMax,
	businessFields = [],
	contact = {},
	socialMediaLinks = [],
	sector,
	lang = '',
	legalForm,
}) => {
	return (
		<div className="container">
			<section>
				<h2>{name}</h2>
				<h4>{translate('Hauptsitz', lang)}</h4>
				<div className="address">
					{name} <br />
					{address.street} {address.houseNumber} <br />
					{address.zipCode} {address.city}
				</div>
				<div className="stats">
					{address.city ? (
						<div>
							<Pin /> <div>{address.city}</div>
						</div>
					) : null}
					{sector ? (
						<div>
							<Factory /> <div>{sector}</div>
						</div>
					) : null}
					{employeeMin ? (
						<div>
							<Group />
							<div>
								{employeeMin} - {employeeMax}
							</div>
						</div>
					) : null}
					{legalForm ? (
						<div>
							<Paragraph /> <div>{legalForm}</div>
						</div>
					) : null}
					<div>
						{businessFields.map(field => (
							<span key={field}>{field}</span>
						))}
					</div>
				</div>
			</section>
			<section>
				<h4>{translate('Unternehmenswebseite', lang)}</h4>
				<a rel="nofollow" href={contact.homepage}>
					{translate('Zur Webseite', lang)}
				</a>
			</section>
			{socialMediaLinks.length > 0 ? (
				<section className="social-media-wrapper">
					<h4>{translate('Social Media', lang)}</h4>
					<div className="social-media-icon-container">
						{socialMediaLinks.map(({ name, url }) => (
							<a rel="nofollow" href={url} key={name}>
								{componentMap[name]}
							</a>
						))}
					</div>
				</section>
			) : null}
			<style jsx>{`
				.container {
					background: white;
					margin: 10px 0;
					border: 1px solid rgb(232, 232, 232);
				}
				:global(.social-media-icon-container svg) {
					width: 35px;
				}
				:global(.stats svg) {
					width: 16px;
					margin-right: 6px;
				}
				:global(.stats path) {
					fill: rgb(85, 85, 85);
				}
				.social-media-icon-container {
					display: flex;
					flex-direction: row;
				}
				.social-media-icon-container a {
					width: 100px;
				}
				section {
					padding: 20px;
					background: white;
				}
				section + section:before {
					content: '';
					width: 100%;
					height: 1px;
					background: rgba(240, 240, 240);
					display: block;
					position: relative;
					top: -20px;
				}
				h2 {
					margin-top: 20px;
					margin-bottom: 16px;
					font-size: 20px;
					font-weight: 500;
					color: ${secondaryAccentColor};
				}
				h4 {
					margin-bottom: 8px;
					font-size: 17px;
					font-weight: 500;
				}
				.address {
					padding-bottom: 20px;
					line-height: 22px;
					font-size: 16px;
				}
				.stats {
					color: #555;
					display: flex;
					flex-wrap: wrap;
				}
				.stats > div {
					margin: 4px 0;
					width: 50%;
					display: flex;
					align-items: center;
				}
				a {
					font-size: 15px;
					text-decoration: none;
					color: ${linkAccentColor};
				}

				@media (max-width: 768px) {
					.container {
						margin: 0;
						border: none;
					}
					section {
						padding: 10px;
						margin-bottom: 20px;
					}
				}
				@media print {
					.address {
						font-size: 12px;
					}
					section a {
						font-size: 0px;
					}
					section a::after {
						content: attr(href);
						font-size: 15px;
						color: black;
					}
					section + section {
						border-top: 0px;
					}
					section {
						page-break-inside: avoid;
					}
					h2 {
						color: #9e9c9c;
						font-size: 16px;
					}
					h4 {
						font-size: 14px;
					}
					.social-media-wrapper {
						display: none;
					}
				}
			`}</style>
		</div>
	)
}

export default CompanyInfo
