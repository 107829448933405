const DotsSpinner: React.FC = () => {
	return (
		<>
			<span className="spinner">
				<span />
				<span />
				<span />
			</span>
			<style jsx>{`
				@keyframes dotanimation {
					0%,
					80%,
					100% {
						transform: scale(0);
					}
					40% {
						transform: scale(1);
					}
				}

				.spinner {
					width: 32px;
				}

				.spinner span {
					animation: dotanimation 1.4s infinite ease-in-out both;
					animation-delay: -0.16s;
					background-color: #ac0;
					border-radius: 100%;
					display: inline-block;
					height: 8px;
					margin-right: 2px;
					width: 8px;
				}

				.spinner span:first-child {
					animation-delay: -0.32s;
				}

				.spinner span:last-child {
					animation-delay: 0;
				}
			`}</style>
		</>
	)
}

export default DotsSpinner
