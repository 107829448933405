import { NextPageContext } from 'next'
import { getNotedOffers, getJobIndex, isUserLoggedIn } from 'shared/api'
import { BrowsingPageProps } from 'shared/interfaces'
import { asPath } from 'shared/Util'

export default async (
	ctx: NextPageContext,
): Promise<Omit<BrowsingPageProps, 'seoBrowsingPage'>> => {
	const [notedOffers, jobIndex] = await Promise.all([
		getNotedOffers(ctx),
		getJobIndex(ctx),
	])
	return {
		asPath: asPath(ctx),
		query: ctx.query,
		notedOffers,
		jobIndex,
		isUserLoggedIn: isUserLoggedIn(ctx),
	}
}
