import { useState } from 'react'
import { useBodyScrollLock } from 'shared/customHooks'

type Props = Readonly<{
	id: string
	isScrollable?: boolean
	isLazy?: boolean
	zIndex?: number
	onChange?: (p: boolean) => void
}>

const Modal: React.FC<Props> = ({
	children,
	id,
	isScrollable,
	zIndex,
	onChange,
	isLazy,
}) => {
	const [isVisible, setIsVisible] = useState(false)
	const setBodyScrollLock = useBodyScrollLock()
	return (
		<>
			<input
				id={`modal-${id}`}
				type="checkbox"
				onChange={event => {
					setBodyScrollLock(event.target.checked)
					setIsVisible(event.target.checked)
					onChange && onChange(event.target.checked)
				}}
			/>
			{isLazy ? isVisible ? <div>{children}</div> : null : <div>{children}</div>}
			<style jsx>{`
				div {
					background-color: rgba(0, 0, 0, 0.5);
					bottom: 0;
					display: none;
					height: 100%;
					left: 0;
					overflow-y: ${isScrollable ? 'scroll' : 'hidden'};
					position: fixed;
					right: 0;
					top: 0;
					width: 100%;
					z-index: ${zIndex !== undefined ? zIndex : 100};
				}

				input {
					display: none;
				}

				input:checked + div {
					display: block;
				}
			`}</style>
		</>
	)
}

export default Modal
