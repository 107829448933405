import React from 'react'
import Download from 'shared/icons/028-download.svg'
import { useState } from 'react'

const Loader = () => {
	return (
		<>
			<div className="loader"></div>
			<style jsx>{`
				.loader,
				.loader:before,
				.loader:after {
					border-radius: 50%;
					width: 2.5em;
					height: 2.5em;
					-webkit-animation-fill-mode: both;
					animation-fill-mode: both;
					-webkit-animation: load7 1.8s infinite ease-in-out;
					animation: load7 1.8s infinite ease-in-out;
				}
				.loader {
					color: #ffffff;
					font-size: 10px;
					top: -0.7em;
					position: relative;
					text-indent: -9999em;
					-webkit-transform: translateZ(0);
					-ms-transform: translateZ(0);
					transform: scale(0.3) translateZ(0);
					-webkit-animation-delay: -0.16s;
					animation-delay: -0.16s;
					margin-right: 20px;
				}
				.loader:before,
				.loader:after {
					content: '';
					position: absolute;
					top: 0;
				}
				.loader:before {
					left: -3.5em;
					-webkit-animation-delay: -0.32s;
					animation-delay: -0.32s;
				}
				.loader:after {
					left: 3.5em;
				}
				@-webkit-keyframes load7 {
					0%,
					80%,
					100% {
						box-shadow: 0 2.5em 0 -1.3em;
					}
					40% {
						box-shadow: 0 2.5em 0 0;
					}
				}
				@keyframes load7 {
					0%,
					80%,
					100% {
						box-shadow: 0 2.5em 0 -1.3em;
					}
					40% {
						box-shadow: 0 2.5em 0 0;
					}
				}
			`}</style>
		</>
	)
}

export const PdfDownloadButton: React.FC<{ downloadUrl: string }> = ({ downloadUrl }) => {
	const [isLoadingState, setIsLoadingState] = useState(false)

	const onClick = () => {
		setIsLoadingState(true)
		setTimeout(() => setIsLoadingState(false), 5000)
	}

	return (
		<>
			{isLoadingState ? (
				<div className="icon">
					<Loader />
				</div>
			) : (
				<a
					className="icon"
					href={downloadUrl}
					target="_blank"
					rel="nofollow"
					download
					onClick={onClick}
				>
					<Download />
				</a>
			)}
			<style jsx>{`
				.icon {
					width: 30px;
					height: 30px;
					cursor: pointer;
					flex-grow: 0;
					flex-shrink: 0;
					margin-right: 10px;
				}
			`}</style>
		</>
	)
}

export default PdfDownloadButton
