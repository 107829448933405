const categoryNames: { [key: string]: string } = {
	position: 'Position',
	standort: 'Standort',
	tätigkeitsbereich: 'Tätigkeitsbereich',
	beschäftigungsart: 'Vertragsart',
	zeitintensität: 'Arbeitszeit',
	zeitarbeit: 'Zeitarbeit',
	skill: 'Kompetenzen',
	benefit: 'Benefit',
	companyName: 'Unternehmen',
}

export default categoryNames
