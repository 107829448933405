import { IJobOffer } from 'shared/interfaces'
import Pin from 'shared/icons/011-pin.svg'
import Calender from 'shared/icons/003-kalender.svg'
import { getTimeAgo, getDateFormat } from 'shared/Util'
import getOfferImgSrc from 'config/getOfferImageSrc'
import { secondaryAccentColor } from 'config/style'

export const getImgSrc = (title: string) => {
	const numberOfHeaderImg = 6
	const number = (title.length % numberOfHeaderImg) + 1
	return '/jobOfferDetails/0' + number + '-header1x.jpg'
}

const JobOffer: React.FC<
	Readonly<IJobOffer> & { translation?: string; lang: string }
> = props => {
	const {
		companyName,
		offerContent,
		title,
		location,
		firstFound,
		children,
		translation,
		lang,
	} = props
	return (
		<>
			<div className="header">
				<div className="header-content">
					{companyName ? <h3>{companyName}</h3> : null}
					<h2>{title}</h2>
				</div>
				<div className="header-bottom">
					{firstFound ? (
						<div className="job-age">
							<Calender className="calender-svg" />
							<h4 className="job-age-time-ago">
								{getTimeAgo(firstFound, lang)}
							</h4>
							<h4 className="job-age-print">
								{getDateFormat(firstFound, lang)}
							</h4>
						</div>
					) : null}
					<div className="location-offer">
						<Pin className={'pin-offer-svg'} />
						<h4>{location}</h4>
					</div>
				</div>
			</div>
			{children}
			<div
				className="content-innerhtml"
				dangerouslySetInnerHTML={{
					__html: translation
						? translation
						: offerContent !== undefined
						? offerContent
						: '',
				}}
			/>
			<style jsx>{`
				:global(.pin-offer-svg, .calender-svg) {
					width: 15px;
					height: 15px;
					position: relative;
					transform: translateY(-50%);
					top: 10px;
					margin-right: 5px;
					min-width: 13px;
				}

				.location-offer,
				.job-age {
					font-size: 14px;
				}
				.location-offer {
					display: flex;
				}

				.job-age {
					margin-right: 20px;
				}

				.header-content {
					align-items: flex-start;
					justify-content: flex-end;
					background-size: cover;
					min-height: 177px;
					background-position: right center;
					background-repeat: no-repeat;
					display: flex;
					flex-direction: column;
					padding: 20px 10px;
				}

				.translation-hint {
					padding-left: 20px;
					padding-top: 10px;
				}

				@media (min-width: 769px) {
					.header-content {
						padding: 20px;
					}
					.header-content h3,
					.header-content h2 {
						max-width: 80%;
						opacity: 0.8;
					}

					.header-content h3 {
						font-size: 16px;
					}
					.header-content h2 {
						font-size: 20px;
					}
				}

				.header-bottom h4 {
					display: inline-block;
					font-weight: 500;
				}
				.header-bottom .job-age-print {
					display: none;
				}

				.header-content h3 {
					background-color: rgb(255, 255, 255);
					color: ${secondaryAccentColor};
					display: inline-block;
					font-size: 15px;
					font-weight: 700;
					opacity: 0.9;
					padding: 5px;
				}
				.header-content h2 {
					background-color: rgb(255, 255, 255);
					color: rgb(0, 0, 0);
					font-size: 18px;
					font-weight: 700;
					line-height: 25px;
					opacity: 0.9;
					padding: 5px;
					margin-top: 5px;
				}

				.header-bottom {
					background-color: rgb(244, 243, 243);
					border-bottom: 1px solid rgb(233, 232, 232);
					padding: 10px 20px;
					display: flex;
					flex-wrap: wrap;
				}

				:global(.content-innerhtml) {
					overflow-x: hidden;
					padding: 10px 20px;
					background: white;
					border: 1px solid rgb(232, 232, 232);
					border-top: none;
				}

				:global(.content-innerhtml h2) {
					margin: 18px 0;
				}

				:global(.content-innerhtml h3) {
					margin: 16px 0;
				}

				:global(.content-innerhtml h4) {
					margin: 16px 0;
				}

				:global(.content-innerhtml ul) {
					padding-left: 40px;
				}

				.header + .content-innerhtml {
					padding-top: 20px;
				}
				.logo {
					display: none;
				}

				@media (max-width: 768px) {
					.header-bottom {
						padding: 10px;
					}
					.job.invisible {
						transform: translateX(110%);
					}
					:global(.content-innerhtml) {
						padding: 10px;
						border: none;
					}

					:global(.content-innerhtml ul) {
						padding-left: 30px;
					}
				}

				.header-content {
					background-image: url(${getOfferImgSrc(props)});
				}

				@media print {
					:global(.content-innerhtml) {
						font-size: 15px;
					}
					:global(.content-innerhtml h2) {
						font-size: 18px;
					}
					:global(.content-innerhtml h3) {
						font-size: 18px;
					}
					.header-content {
						padding-top: 0px;
						padding-left: 0px;
						min-height: initial;
					}

					.header {
						padding: 20px 0px 0px 20px;
						border: 1px solid #e5e5e5;
						max-height: 200px;
						background-color: #fafafa;
					}
					.header h2 {
						text-align: left;
						color: #000000;
						text-shadow: none;
						font-size: 20px;
						background-color: #fafafa;
						margin-bottom: 50px;
					}
					.header h3 {
						text-align: left;
						text-shadow: none;
						color: #9e9c9c;
						font-size: 16px;
						padding-top: 0px;
						background-color: #fafafa;
					}
					.header-bottom {
						position: relative;
						top: -75px;
						background-color: #fafafa;
						border-bottom: none;
						color: #9e9c9c;
						flex-direction: column-reverse;
					}
					.header-content {
						background-image: none;
					}
					.location-offer {
						margin-bottom: 10px;
					}
					.header-bottom .job-age-print {
						display: inline-block;
					}
					.header-bottom .job-age-time-ago {
						display: none;
					}
				}
			`}</style>
		</>
	)
}

export default JobOffer
