import { NextPageContext } from 'next'
import {
	getSearchResults,
	getJob,
	getSimilarJobs,
	getTopJobs,
	getNotedOffers,
	getHost,
	isUserLoggedIn as getIsUserLoggedIn,
	getUser,
	getNotifications,
} from '../api'
import {
	SearchPageQueryParams,
	SearchPageProps,
	ISearchResults,
	IJobOfferDetails,
	ISimilarJob,
	INotedOffer,
	User,
	Notification,
} from '../interfaces'
import { asPath, getSearchTermsFromSearchQuery } from 'shared/Util'
import { isMobile } from '../userAgentParser'

const getFiltersFromQuery = (query: SearchPageQueryParams) => {
	if (Array.isArray(query.pf)) {
		return query.pf
	}

	return (query.pf ? [query.pf] : []) as string[]
}

const getCompareableSearchTerms = (searchTerms: string[]) => {
	return searchTerms.sort().join('')
}

export default async (ctx: NextPageContext): Promise<SearchPageProps> => {
	const query = { ...ctx.query }
	const searchPageQueryParams: SearchPageQueryParams = query
	const { q, loc, page = '0', r, selected, sgid, sid } = searchPageQueryParams
	const pageAsInt = parseInt(page, 10)
	let searchResults: ISearchResults | undefined
	let selectedJob: IJobOfferDetails | undefined
	let similarJobs: ISimilarJob[] = []
	let topJobs: ISimilarJob[] = []
	let notedOffers: INotedOffer[] = []
	let user: User | undefined
	let notifications: Notification[] | undefined
	const queryFilters = getFiltersFromQuery(searchPageQueryParams)
	const isUserLoggedIn = getIsUserLoggedIn(ctx)

	if (selected && (q || loc)) {
		;[
			searchResults,
			selectedJob,
			similarJobs,
			topJobs,
			notedOffers,
			user,
			notifications,
		] = await Promise.all([
			getSearchResults(ctx, {
				freeText: q,
				location: loc,
				page: pageAsInt,
				radius: r,
				filters: queryFilters,
				searchGroupId: sgid,
				searchId: sid,
			}),
			selected ? getJob(ctx, selected).catch(() => undefined) : undefined,
			selected ? getSimilarJobs(ctx, selected).catch(() => []) : [],
			selected ? getTopJobs(ctx, selected).catch(() => []) : [],
			getNotedOffers(ctx),
			isUserLoggedIn ? getUser(ctx).catch(() => undefined) : undefined,
			isUserLoggedIn ? getNotifications(ctx).catch(() => undefined) : undefined,
		])
	} else if (q || loc) {
		;[searchResults, notedOffers, user, notifications] = await Promise.all([
			getSearchResults(ctx, {
				freeText: q,
				location: loc,
				page: pageAsInt,
				radius: r,
				filters: queryFilters,
				searchGroupId: sgid,
				searchId: sid,
			}),
			getNotedOffers(ctx),
			isUserLoggedIn ? getUser(ctx).catch(() => undefined) : undefined,
			isUserLoggedIn ? getNotifications(ctx).catch(() => undefined) : undefined,
		])
	} else {
		searchResults = {
			filter: {
				categories: [],
				numberOfSelectedFilters: 0,
			},
			jobOffers: [],
			resultSize: 0,
			searchGroupId: '',
			searchId: '',
			statistics: {},
		}
	}

	if (
		!isMobile() &&
		!selectedJob &&
		searchResults &&
		searchResults.jobOffers.length > 0
	) {
		const documentId = searchResults.jobOffers[0].documentId
		;[selectedJob, similarJobs, topJobs, user, notifications] = await Promise.all([
			getJob(ctx, documentId).catch(() => undefined),
			getSimilarJobs(ctx, documentId).catch(() => []),
			getTopJobs(ctx, documentId).catch(() => []),
			isUserLoggedIn ? getUser(ctx).catch(() => undefined) : undefined,
			isUserLoggedIn ? getNotifications(ctx).catch(() => undefined) : undefined,
		])
	}

	const currentSearchTerms = getCompareableSearchTerms(
		getSearchTermsFromSearchQuery(searchPageQueryParams),
	)
	const hasNotificationForCurrentSearch =
		notifications &&
		notifications
			.map(notifications => getCompareableSearchTerms(notifications.SearchTerms))
			.indexOf(currentSearchTerms) > -1
			? true
			: false

	return {
		asPath: asPath(ctx),
		page: pageAsInt,
		query: query,
		searchQuery: searchPageQueryParams,
		queryFilters,
		searchResults,
		selectedJob,
		similarJobs,
		topJobs,
		notedOffers,
		host: getHost(ctx.req),
		isUserLoggedIn,
		user,
		hasNotificationForCurrentSearch,
	}
}
